import { useCallback, useState } from "react";

import { userService } from "@/config/services";
import { selectTrasactions, useAppSelector } from "@/services/Store";
import Button from "@/components/input/Button";
import Modal, { useModalState } from "@/components/misc/Modal";
import ImageTitleContent from "@/components/data/ImageTitleContent";

function useRequestPayout(params: { milestoneId: number }) {
  const { milestoneId } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [requested, setRequested] = useState(false);
  const modalState = useModalState();

  const transactions = useAppSelector(selectTrasactions);
  const isFirstPayout =
    transactions.length === 1 &&
    transactions[0].metadata.milestone.id === milestoneId;

  const triggerRequest = useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    return userService
      .requestPayout({ milestoneId })
      .then(() => {
        setRequested(true);
      })
      .finally(() => {
        userService.fetchMilestoneTransaction(milestoneId).finally(() => {
          setIsLoading(false);
        });
      });
  }, [isLoading, milestoneId]);

  const request = useCallback(() => {
    if (isFirstPayout) {
      modalState.open();
      return;
    }

    return triggerRequest();
  }, [triggerRequest, isFirstPayout, modalState]);

  const jsx = (
    <Modal state={modalState} width="360px">
      <ImageTitleContent
        image="/assets/images/pages/transactions/request_payout.svg"
        title="Your first payout"
        content={
          <>
            To ensure payment security, Paypipe may hold your payout for review
            before sending it to your account. Accumulated funds will be paid
            out together.
          </>
        }
      />
      <Button
        className="w-100 mt-4"
        disabled={isLoading}
        onClick={() => {
          triggerRequest();
        }}
      >
        I understand
      </Button>
    </Modal>
  );

  return {
    jsx,
    request,
    isLoading,
    requested,
  };
}

export default useRequestPayout;
