import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useForm } from "@/components/input/Form";
import { ContractCompleteDetails } from "@/services/ContractsService";
import { useNavigate, usePathname, useSearchParams } from "@/services/Routing";
import { useOnboardStripe } from "@/services/UserService";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { SITE_PATHS } from "@/config/routing";
import { analytics } from "@/config/services";
import { OfferFormEvents } from "@/services/Analytics";
import Modal, { useModalState } from "@/components/misc/Modal";

import {
  ClientInputType,
  ContractFormRef,
  ContractFormValues,
  IContractFormContext,
  IContractFormProps,
  Step,
} from "./ContractForm.types";
import {
  STEPS_CONFIG,
  STEPS_LIST,
  STEP_URL_PARAM_NAME,
} from "./ContractForm.config";
import SuccessScreen from "./components/screens/SuccessScreen";
import DescripitonTip from "./components/misc/DescripitonTip";

const INITIAL_VALUES = {} as IContractFormContext;

const ContractFormContext = createContext(INITIAL_VALUES);

export const useContractForm = () => useContext(ContractFormContext);

export const ContractFormProvider: React.FC<
  IContractFormProps & {
    creatingContract: boolean;
    contractCreated: boolean;
    createdContractDetails: null | ContractCompleteDetails;
  }
> = ({
  getFormRef,
  onStepChange,
  contractCreated,
  createdContractDetails,
  initialValues,
  creatingContract,
  className,
}) => {
  const { navigate, back } = useNavigate();
  const { pathname, url: currentUrl } = usePathname();
  const { searchParams } = useSearchParams();

  const ref = useRef({} as ContractFormRef);
  const stepContainerElRef = useRef<HTMLDivElement | null>(null);

  const formContextValue = useForm<ContractFormValues>();
  const { values, errors } = formContextValue;

  const [clientInputType, setClientInputType] = useState<ClientInputType>(
    !values.client_paypipe_id && !!values.client_email_number
      ? ClientInputType.Email
      : ClientInputType.PaypipeId
  );

  const descripitonTipModalState = useModalState();
  const [alreadyShownDecriptionTip, setAlreadyShownDecriptionTip] =
    useState(false);

  const { canOnboard, isReady, updateCanOnboardState } = useOnboardStripe();
  const stepsList = useMemo(() => {
    let steps = STEPS_LIST;

    if (canOnboard) {
      steps = STEPS_LIST.filter(
        ({ id }) =>
          ![Step.ContractType, Step.ContractDetails, Step.Review].includes(
            id as Step
          )
      );
    } else {
      steps = STEPS_LIST.filter(({ id }) => id !== Step.PayoutOnboarding);
    }

    return steps;
  }, [canOnboard]);

  const setStepUrlParam = useCallback(
    (step: Step, replace = false) => {
      searchParams.delete(STEP_URL_PARAM_NAME);
      searchParams.set(STEP_URL_PARAM_NAME, STEPS_CONFIG[step]?.urlId || "");
      const path = `${pathname}?${searchParams.toString()}`;

      navigate(path, replace);
    },
    [pathname, searchParams, navigate]
  );

  const gotToStep = useCallback(
    (step: Step) => {
      setCurrentStep(step);

      if (onStepChange) {
        onStepChange(step);
      }

      if (stepContainerElRef.current) stepContainerElRef.current.scrollTop = 0;

      setStepUrlParam(step);
    },
    [onStepChange, setStepUrlParam]
  );

  const [currentStep, setCurrentStep] = useState<Step>(() => {
    let currentStep = Step.ContractDetails;

    if (!initialValues?.created_as) {
      currentStep = Step.ContractCreator;
      setStepUrlParam(Step.ContractCreator, true);
    } else if (canOnboard) {
      currentStep = Step.PayoutOnboarding;
      setStepUrlParam(Step.PayoutOnboarding, true);
    } else if (!initialValues?.contract_type) {
      currentStep = Step.ContractType;
      setStepUrlParam(Step.ContractType, true);
    } else {
      currentStep = Step.ContractDetails;
      setStepUrlParam(Step.ContractDetails, true);
    }

    if (onStepChange) {
      onStepChange(currentStep);
    }

    return currentStep;
  });

  const isLastStep = currentStep === stepsList[stepsList.length - 1].id;

  // ---------------------

  const renderStep = useCallback(
    () => STEPS_CONFIG[currentStep]?.component,
    [currentStep]
  );

  const getStepIndex = useCallback(
    (step: Step) => {
      const index = stepsList.findIndex(({ id }) => id === step);

      return index;
    },
    [stepsList]
  );

  const previousStep = useCallback(() => {
    const currentStepIndex = getStepIndex(currentStep);

    if (currentStepIndex === 0) {
      if (currentUrl.includes(SITE_PATHS.CREATE_CONTRACT_PAGE)) {
        navigate(SITE_PATHS.HOME_PAGE);
        return;
      }

      back();
      return;
    }

    const nextStepIndex = Math.max(0, currentStepIndex - 1);
    gotToStep(stepsList[nextStepIndex].id as Step);
  }, [
    currentStep,
    gotToStep,
    getStepIndex,
    stepsList,
    back,
    currentUrl,
    navigate,
  ]);

  const nextStep = useCallback(() => {
    const currentStepIndex = getStepIndex(currentStep);

    if (currentStepIndex === stepsList.length - 1) {
      return;
    }

    const nextStepIndex = Math.min(stepsList.length - 1, currentStepIndex + 1);
    gotToStep(stepsList[nextStepIndex].id as Step);
  }, [currentStep, gotToStep, getStepIndex, stepsList]);

  const checkIsStepCompleted = useCallback(
    (step: Step) => {
      const validityFunction = STEPS_CONFIG[step]?.isStepCompleted;
      const isCompleted = validityFunction
        ? validityFunction({ values, errors })
        : true;

      return isCompleted;
    },
    [values, errors]
  );

  const shownDecriptionTip = useCallback(() => {
    if (alreadyShownDecriptionTip) {
      return;
    }

    setAlreadyShownDecriptionTip(true);
    descripitonTipModalState.open();
  }, [alreadyShownDecriptionTip, descripitonTipModalState]);

  // ---------------------

  useEffect(() => {
    analytics.triggerOfferFormEvent("offer_form_started");
  }, []);

  useEffect(() => {
    ref.current = {
      nextStep,
      previousStep,
    };

    if (getFormRef) getFormRef(ref.current);
  }, [nextStep, previousStep, getFormRef]);

  const stepUrlId =
    searchParams.get(STEP_URL_PARAM_NAME) ||
    STEPS_CONFIG.ContractCreator?.urlId;
  useEffect(() => {
    const step: any = STEPS_LIST.find(({ urlId }) => urlId === stepUrlId);

    if (step && step.id !== currentStep) {
      gotToStep(step.id);
    }
  }, [stepUrlId, currentStep, gotToStep]);

  const updateCanOnboardStateRef = useRef(updateCanOnboardState);
  useEffect(() => {
    if (isReady) {
      updateCanOnboardStateRef.current(true);
    }
  }, [isReady]);

  useEffect(() => {
    let eventName: OfferFormEvents | null = null;

    if (currentStep) {
      eventName = STEPS_CONFIG[currentStep]?.analyticsEventName || null;
    }

    if (eventName) {
      analytics.triggerOfferFormEvent(eventName);
    }
  }, [currentStep]);

  // ---------------------

  if (!isReady) {
    return <SplashScreen />;
  }

  return (
    <ContractFormContext.Provider
      value={{
        ...formContextValue,
        currentStep,
        clientInputType,
        setClientInputType,
        createdContractDetails,
        checkIsStepCompleted,
        gotToStep,
        previousStep,
        nextStep,
        isLastStep,
        creatingContract,
        shownDecriptionTip,
        alreadyShownDecriptionTip,
      }}
    >
      {contractCreated ? (
        <SuccessScreen />
      ) : (
        <form className={className}>
          {renderStep()}
          {descripitonTipModalState.isOpen && (
            <Modal
              showCloseButton
              state={descripitonTipModalState}
              contentContainerProps={{
                style: {
                  padding: "1rem",
                },
              }}
            >
              <DescripitonTip />
            </Modal>
          )}
        </form>
      )}
    </ContractFormContext.Provider>
  );
};
