import {
  ContractCompleteDetails,
  ContractCreatorType,
  ContractType,
  isMilestoneReviewed,
  isMilestoneWorkSubmitted,
  getNextActiveMilestone,
  isContractCompleted,
  isMilestoneCompleted,
  isMilestoneFundable,
  isMilestoneReviewable,
  isMilestoneWorkSubmittable,
  isMilestoneFunded,
  ContractMilestoneStatus,
  getMilestonesMetaData,
  getContractFundDate,
  isMilestoneStartable,
} from "@/services/ContractsService";
import { Body } from "@/components/Typography";
import { formatDate } from "@/utils/date";
import { ColorVariant } from "@/components/misc/Tag";
import { padText } from "@/utils/string";
import StartMilestoneButton from "@/features/contracts/StartMilestoneButton";
import ReleasePaymentButton from "@/features/contracts/ReleasePaymentButton";
import RequestPaymentButton from "@/features/contracts/RequestPaymentButton";

import { ITimelineItemProps } from "./components/TimelineItem";
import { MILESTONE_STATUS_CONFIG } from "./ContractTimeline.config";
import FundMilestoneButton from "./components/FundMilestoneButton";
import SubmitWorkButton from "./components/SubmitWorkButton";
import { CtaAction } from "./ContractTimeline.types";
import ReviewWorkButton from "./components/ReviewWorkButton";
import { Milestone } from "./ContractTimeline.styles";

const formatDateWithTime = (date: string) =>
  `${formatDate(date, "MMM D, YYYY")} at ${formatDate(date, "HH:mm")}`;

function getJobStartedDummyTimelineItem(): ITimelineItemProps {
  return {
    title: {
      content: "Job Started",
    },
    tag: {
      content: "Offer Accepted",
      color: "success",
    },
    description: {
      content: "Jan 19, 2024 at 22:07",
    },
  };
}

function getJobStartedTimelineItem({
  contract,
}: {
  contract: ContractCompleteDetails;
}): ITimelineItemProps {
  const displayDate = getContractFundDate(contract);
  const displayDateFormatted = displayDate
    ? formatDateWithTime(displayDate)
    : "";

  return {
    title: {
      content: "Job Started",
    },
    tag: {
      content: "Offer Accepted",
      color: "success",
    },
    secondaryInfo: {
      content: displayDateFormatted,
    },
  };
}

function getJobCompletedTimelineItem(): ITimelineItemProps {
  return {
    title: {
      content: "Job Completed",
    },
  };
}

function getDummyMilestoneTimelineItem(): ITimelineItemProps {
  return {
    title: {
      content: "Milestone 1",
      fullWidth: true,
    },
    tag: {
      content: "Paid",
      color: "success",
    },
    value: {
      content: "$800",
    },
    description: {
      content: "Wireframes",
      bold: true,
    },
    secondaryInfo: {
      content: "Jan 19, 2024",
    },
  };
}

function getMilestoneTimelineItem({
  milestoneIndex,
  contract,
  userType,
  disableControls,
}: {
  milestoneIndex: number;
  contract: ContractCompleteDetails;
  userType: ContractCreatorType;
  disableControls?: boolean;
}): ITimelineItemProps[] {
  const { id: contractId, milestones, contract_type } = contract;
  const milestone = milestones[milestoneIndex];
  const { id: milestoneId, name, value, end_date, has_work } = milestone;

  const amIPayer = userType === ContractCreatorType.Payer;
  const amIPayee = userType === ContractCreatorType.Payee;
  const isFunded = isMilestoneFunded(milestone);

  const isFundable = isMilestoneFundable(milestone);
  // const canFund = isFundable && userType === ContractCreatorType.Payer;

  const isWorkSubmittable = isMilestoneWorkSubmittable(milestone);

  const isStartable = isMilestoneStartable(milestone);

  const isReviewable = isMilestoneReviewable(milestone);
  // const canReview = isReviewable && userType === ContractCreatorType.Payer;
  const isCompleted = isMilestoneCompleted(milestone);

  const isWorkSubmitted = isMilestoneWorkSubmitted(milestone);

  const { workSubmittedDate, paymentReleasedDate, milestoneFundedDate } =
    getMilestonesMetaData(contract)[milestoneId];

  const displayDate = isFunded ? milestoneFundedDate : end_date;
  const displayDateFormatted = displayDate
    ? formatDateWithTime(displayDate)
    : "";

  let children: React.ReactNode = null;
  let ctaButton: React.ReactNode = null;
  let ctaAction: CtaAction | undefined;

  const tagDetails = isFunded
    ? MILESTONE_STATUS_CONFIG[ContractMilestoneStatus.Funded]?.content[userType]
        ?.tag ||
      MILESTONE_STATUS_CONFIG[ContractMilestoneStatus.Funded]?.content?.common
        ?.tag
    : undefined;

  const { index: nextActiveMilestoneIndex } = getNextActiveMilestone(contract);

  if (isFundable) {
    if (amIPayer) {
      ctaAction = "fund";
      ctaButton = (
        <FundMilestoneButton
          contractId={contractId}
          milestoneId={milestoneId}
        />
      );
      children = <div className="mt-2">{ctaButton}</div>;
    }
  } else if (isWorkSubmittable) {
    if (amIPayee) {
      ctaAction = "submit-work";
      ctaButton = has_work ? (
        <RequestPaymentButton contractDetails={contract} />
      ) : (
        <SubmitWorkButton contractId={contractId} milestoneId={milestoneId} />
      );
      children = (
        <div className="mt-2">
          {ctaButton}

          <Body size="sm" className="mt-2">
            You can always choose to request payment without submitting work, by
            clicking on request payment below.
          </Body>
        </div>
      );
    }

    if (amIPayer && has_work) {
      ctaAction = "review";
      ctaButton = (
        <ReviewWorkButton contractId={contractId} milestoneId={milestoneId} />
      );
      children = <div className="mt-2">{ctaButton}</div>;
    }
  } else if (isReviewable) {
    if (amIPayer) {
      ctaAction = "review";
      ctaButton = has_work ? (
        <ReviewWorkButton contractId={contractId} milestoneId={milestoneId} />
      ) : (
        <ReleasePaymentButton contractDetails={contract} />
      );
      children = (
        <div className="mt-2">
          {ctaButton}

          {has_work && (
            <Body size="sm" className="mt-2">
              You can always choose to release payment without reviewing work,
              by clicking on release payment.
            </Body>
          )}
        </div>
      );
    }
  } else if (isStartable) {
    if (amIPayee) {
      ctaAction = "start";
      ctaButton = (
        <StartMilestoneButton
          contractId={contractId}
          milestoneId={milestoneId}
        />
      );
      children = <div className="mt-2">{ctaButton}</div>;
    }
  }

  const ctaContent = disableControls
    ? {}
    : {
        children,
        ctaButton,
        ctaAction,
      };
  const isMilestoneContract = contract_type === ContractType.Milestone;

  const fundableTag =
    MILESTONE_STATUS_CONFIG[ContractMilestoneStatus.Accepted]?.content[userType]
      ?.tag ||
    MILESTONE_STATUS_CONFIG[ContractMilestoneStatus.Accepted]?.content?.common
      ?.tag;

  const items: ITimelineItemProps[] = [
    {
      isMilestoneItem: true,
      isSubItem: milestoneIndex === 0,
      title: {
        content: isMilestoneContract ? (
          <Milestone>Milestone {padText(milestoneIndex + 1, 2, "0")}</Milestone>
        ) : (
          name
        ),
        bold: !isMilestoneContract,
      },
      tag:
        milestoneIndex === nextActiveMilestoneIndex && isFundable
          ? fundableTag
          : tagDetails,
      value: {
        content: `$${value || 0}`,
      },
      secondaryInfo: {
        content: displayDateFormatted,
      },
      description: isMilestoneContract
        ? {
            content: name,
            bold: true,
          }
        : {
            content: "",
          },
      ...(milestoneIndex === nextActiveMilestoneIndex &&
      (disableControls || isReviewable || isWorkSubmittable)
        ? {}
        : ctaContent),
    },
  ];

  const isContractCompleted_ = isContractCompleted(contract);

  if (isContractCompleted_ || milestoneIndex <= nextActiveMilestoneIndex) {
    if (
      amIPayer &&
      has_work &&
      milestoneIndex === nextActiveMilestoneIndex &&
      !isReviewable &&
      !disableControls
    ) {
      items.push({
        isSubItem: true,
        ...(milestoneIndex === nextActiveMilestoneIndex ? ctaContent : {}),
      });
    }
    
    if (amIPayee) {
      if (isWorkSubmittable) {
        items.push({
          isSubItem: true,
          ...(milestoneIndex === nextActiveMilestoneIndex ? ctaContent : {}),
        });
      }
    }
    
    if (isWorkSubmitted) {
      const dateFormatted = workSubmittedDate
        ? formatDateWithTime(workSubmittedDate)
        : "";

      items.push({
        isSubItem: true,
        title: {
          content: "Payment requested",
        },
        description: dateFormatted
          ? {
              content: dateFormatted,
            }
          : undefined,
        ...(milestoneIndex === nextActiveMilestoneIndex &&
        isReviewable &&
        !disableControls
          ? ctaContent
          : {}),
      });
    }

    if (isCompleted) {
      const dateFormatted = paymentReleasedDate
        ? formatDateWithTime(paymentReleasedDate)
        : "";

      items.push({
        isSubItem: true,
        title: {
          content: "Payment released",
        },
        description: dateFormatted
          ? {
              content: dateFormatted,
            }
          : undefined,
        tag: {
          content: "Paid",
          color: "success",
        },
      });
    }
  }

  return items;
}

export function generateDummyTimelineItems() {
  const items: (ITimelineItemProps & { id: any })[] = [
    {
      id: Math.random(),
      ...getJobStartedDummyTimelineItem(),
    },
  ];

  // @ts-ignore
  [...Array(7).keys()].forEach((_, i) => {
    items.push({
      id: Math.random(),
      ...getDummyMilestoneTimelineItem(),
    });
  });

  items.push({
    id: Math.random(),
    ...getJobCompletedTimelineItem(),
  });

  return { items };
}

//--------> Milestone

export function generateMilestoneContractTimelineItems({
  contract,
  userType,
  disableControls,
}: {
  contract: ContractCompleteDetails;
  userType: ContractCreatorType;
  disableControls?: boolean;
}) {
  const { milestones } = contract;
  let currentIndex = 0;

  const isCompleted = isContractCompleted(contract);
  const { milestone: nextActiveMilestone } = getNextActiveMilestone(contract);

  const items: (ITimelineItemProps & { id: any })[] = [
    {
      id: "start",
      ...getJobStartedTimelineItem({ contract }),
    },
  ];

  let itemIndex = 0;
  milestones.forEach(({ id }, i) => {
    itemIndex += 1;

    if (nextActiveMilestone?.id === id) {
      currentIndex = itemIndex;
    }

    const items_ = getMilestoneTimelineItem({
      contract,
      milestoneIndex: i,
      userType,
      disableControls,
    });

    items_.forEach((item, j) => {
      if (j > 0) {
        itemIndex += 1;

        if (nextActiveMilestone?.id === id) {
          currentIndex = itemIndex;
        }
      }

      items.push({
        id: `${id}_${j}`,
        ...item,
        // title: {
        //   content: (
        //     <>
        //       {item?.title?.content} [{itemIndex}]
        //     </>
        //   ),
        //   content: item.title.content,
        // },
      });
    });
  });

  if (isCompleted) {
    items.push({
      id: "end",
      isSubItem: true,
      ...getJobCompletedTimelineItem(),
    });

    currentIndex = items.length;
  }

  return { items, currentIndex };
}

//--------> One time

export function generateOnetimeContractTimelineWorkItem({
  contract,
  userType,
}: {
  contract: ContractCompleteDetails;
  userType: ContractCreatorType;
}): ITimelineItemProps {
  const { id: contractId, milestones } = contract;
  const firstMilestone = milestones[0];
  const { id: milestoneId } = firstMilestone;
  const isSubmittable = isMilestoneWorkSubmittable(firstMilestone);
  const canSubmit = isSubmittable && userType === ContractCreatorType.Payee;
  const isWorkSubmitted = isMilestoneWorkSubmitted(firstMilestone);

  const tagDetails = isSubmittable
    ? { content: "Active", color: "primary" as ColorVariant }
    : isWorkSubmitted
    ? { content: "Submitted", color: "success" as ColorVariant }
    : undefined;

  const ctaButton = canSubmit ? (
    <SubmitWorkButton contractId={contractId} milestoneId={milestoneId} />
  ) : null;

  let ctaAction: CtaAction | undefined;
  if (canSubmit) {
    ctaAction = "submit-work";
  }

  const children = canSubmit ? (
    <div className="mt-2">
      {ctaButton}

      <Body size="sm" className="mt-2">
        You can always choose to request payment without submitting work, by
        clicking on request payment below.
      </Body>
    </div>
  ) : null;

  return {
    title: {
      content: "Work in Progress",
    },
    children,
    tag: tagDetails,
    ctaButton,
    ctaAction,
  };
}

export function generateOnetimeContractTimelineReviewItem({
  contract,
  userType,
}: {
  contract: ContractCompleteDetails;
  userType: ContractCreatorType;
}): ITimelineItemProps {
  const { id: contractId, milestones } = contract;
  const firstMilestone = milestones[0];
  const { id: milestoneId } = firstMilestone;
  const isReviewable = isMilestoneReviewable(firstMilestone);
  const canReview = isReviewable && userType === ContractCreatorType.Payer;
  const isReviewed = isMilestoneReviewed(firstMilestone);

  const tagDetails = isReviewable
    ? { content: "Active", color: "primary" as ColorVariant }
    : isReviewed
    ? { content: "Paid", color: "success" as ColorVariant }
    : undefined;

  const ctaButton = canReview ? (
    <ReviewWorkButton contractId={contractId} milestoneId={milestoneId} />
  ) : null;

  let ctaAction: CtaAction | undefined;
  if (canReview) {
    ctaAction = "review";
  }

  const children = canReview ? (
    <div className="mt-2">
      {ctaButton}

      <Body size="sm" className="mt-2">
        You can always choose to release payment without reviewing work, by
        clicking on release payment below.
      </Body>
    </div>
  ) : null;

  return {
    title: {
      content: "Client Reviewing",
    },
    children,
    tag: tagDetails,
    ctaAction,
  };
}

export function generateOnetimeContractTimelineItems({
  contract,
  userType,
}: {
  contract: ContractCompleteDetails;
  userType: ContractCreatorType;
}) {
  let currentIndex = 0;

  const isCompleted = isContractCompleted(contract);

  const items: (ITimelineItemProps & { id: any })[] = [
    {
      id: "start",
      ...getJobStartedTimelineItem({ contract }),
    },
  ];

  const { milestones } = contract;
  const firstMilestone = milestones[0];
  const isContractSubmittable = isMilestoneWorkSubmittable(firstMilestone);
  const isContractReviewable = isMilestoneReviewable(firstMilestone);

  items.push({
    id: "work",
    ...generateOnetimeContractTimelineWorkItem({ contract, userType }),
  });
  if (isContractSubmittable) currentIndex = 1;

  items.push({
    id: "work",
    ...generateOnetimeContractTimelineReviewItem({ contract, userType }),
  });
  if (isContractReviewable) currentIndex = 2;

  items.push({
    id: "end",
    ...getJobCompletedTimelineItem(),
  });

  if (isCompleted) {
    currentIndex = items.length;
  }

  return { items, currentIndex };
}

//--------------------------------------

export function generateTimelineItems({
  contract,
  userType,
  disableControls,
}: {
  contract: ContractCompleteDetails;
  userType: ContractCreatorType;
  disableControls?: boolean;
}) {
  const { contract_type } = contract;

  if (contract_type === ContractType.Milestone) {
    return generateMilestoneContractTimelineItems({
      contract,
      userType,
      disableControls,
    });
  }

  if (contract_type === ContractType.OneTime) {
    return generateMilestoneContractTimelineItems({
      contract,
      userType,
      disableControls,
    });
  }

  return { items: [], currentIndex: -1 };
}
