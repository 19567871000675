import { useMemo } from "react";
import styled from "@emotion/styled/macro";

import {
  ContractCreatorType,
  getLoggedInUserContractUserType,
} from "@/services/ContractsService";

import { useContractManagement } from "../ContractManagementPage.context";
import Icon from "@/components/misc/Icon";
import { Body } from "@/components/Typography";

type Status = "pending" | "funding-failed" | "funding-retry";

type Config = {
  title?: React.ReactNode;
  content?: React.ReactNode;
  icon?: string;
  bgColor?: string;
};

const CONFIG: Record<
  Status,
  {
    default?: Config;
    [ContractCreatorType.Payee]?: Config;
    [ContractCreatorType.Payer]?: Config;
  }
> = {
  pending: {
    default: {
      title: "Funding In Progess",
      bgColor: "#FFF7ED",
      icon: "cash-flow/funding_progress.svg",
    },
    [ContractCreatorType.Payee]: {
      content:
        "Your client has paid using bank transfer which takes some time to complete. We'll notify you once this offer is marked as funded and you can start working.",
    },
    [ContractCreatorType.Payer]: {
      content:
        "You have paid using bank transfer which takes some time to complete. We'll notify you and the service provider when the payment is completed and the offer is marked as funded.",
    },
  },
  "funding-retry": {
    default: { bgColor: "#FEF2F2", icon: "cash-flow/funding_failed.svg" },
    [ContractCreatorType.Payee]: {
      title: "Funding Failed",
      content:
        "Unfortunately the funding didn't succeed, we have notified your client to retry payment. They have 3 days to complete payment otherwise this offer will b archived and cannot be funded.",
    },
    [ContractCreatorType.Payer]: {
      title: "Bank Transfer Failed",
      content:
        "Unfortunately, your payment was unsuccessful. You have 3 days to retry payment otherwise this offer will be archived and cannot be funded.",
    },
  },
  "funding-failed": {
    default: {
      title: "Funding Failed",
      content:
        "Unfortunately, after trying multiple times, the payment was not completed. This offer is archived and cannot be funded again.",
      bgColor: "#FEF2F2",
      icon: "cash-flow/funding_failed.svg",
    },
  },
};

const StyledContainer = styled.div`
  padding: 1rem;
  border-radius: 0.75rem;
`;

const StyledHeading = styled.div`
  display: flex;
  color: #0d0d12;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const StyledValue = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #0d0d12;
`;

const StyledTopSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  ${StyledHeading} {
    flex-grow: 1;
  }
`;

const BankFundingStatus: React.FC<{ className?: string }> = ({ className }) => {
  const { contractDetails, paymentStatus } = useContractManagement();

  const isBankAccountPayment = !!paymentStatus?.payment?.isBankAccountPayment;
  const paymentCompleted = !!paymentStatus?.payment?.paymentCompleted;
  const succeeded = !!paymentStatus?.payment?.succeeded;
  const value = paymentStatus?.payment?.value;
  const status: Status | null =
    isBankAccountPayment && succeeded && !paymentCompleted ? "pending" : null;
  const userType = useMemo(
    () => getLoggedInUserContractUserType(contractDetails),
    [contractDetails]
  );

  if (!status) {
    return null;
  }

  const defaultConfig = CONFIG[status].default || {};
  const userTypeConfig = userType ? CONFIG[status][userType] || {} : {};
  const { title, content, icon, bgColor } = {
    ...defaultConfig,
    ...userTypeConfig,
  };
  const displayValue = value ? `$${value / 100}` : "";

  return (
    <StyledContainer className={className} style={{ background: bgColor }}>
      <StyledTopSection>
        {!!icon && <Icon isSrcRelative src={icon} />}
        <StyledHeading>{title}</StyledHeading>
        <StyledValue>{displayValue}</StyledValue>
      </StyledTopSection>
      <Body size="lg" className="mt-3" style={{ color: "#666D80" }}>
        {content}
      </Body>
    </StyledContainer>
  );
};

export default BankFundingStatus;
