import useSWR from "swr";
import { useMemo } from "react";

import { getUrl } from "@/utils/string";
import { userService } from "@/config/services";
import { selectTrasactions, useAppSelector } from "@/services/Store";

import { TranscationFilters } from "../UserService.types";

function useTransactions(filters: TranscationFilters) {
  const { url: cacheKey } = useMemo(
    () =>
      getUrl({
        endpoint: "user/transactions",
        queryParams: filters,
      }),
    [filters]
  );

  const transactionsFromHook = useAppSelector(selectTrasactions);

  const { data, error, isLoading, mutate } = useSWR(
    cacheKey,
    () =>
      userService.fetchTransactions(filters).then((res) => res.transactions),
    {
      dedupingInterval: 30000,
    }
  );

  const isUpdating = data !== undefined && isLoading;
  const noData = !isLoading && !data;

  //-----------------------

  return {
    data: transactionsFromHook || [],
    error,
    isLoading,
    isUpdating,
    refresh: () => mutate(),
    noData,
  };
}

export default useTransactions;
