import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import {
  getViewPortHeightCssString,
  screenLargerThan,
  screenSmallerThan,
} from "@/styles";
import Logo from "@/features/app/Logo";

export const StyledContainer = styled.div`
  counter-reset: section;

  & > *:first-child {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;

    ${screenLargerThan.tablet} {
      height: 100%;
      overflow: hidden;
    }
  }

  ${screenSmallerThan.tablet} {
    min-height: ${getViewPortHeightCssString()};
    background-color: var(--clr-primary-100, #5f57ff);
  }
`;

export const StyledTopSection = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 1.25rem;
  padding-bottom: 3rem;
  color: var(--clr-text-white, #fff);
  background-color: linear-gradient(
    40deg,
    rgba(95, 87, 255, 1) 0%,
    rgba(95, 87, 255, 1) 39%,
    rgba(57, 52, 153, 1) 100%
  );
  background-position: bottom center;
  background-repeat: no-repeat;
  min-height: 180px;

  p {
    margin: 0;
    font-size: 24px;
    line-height: 140%;
    font-weight: 600;
  }

  ${screenSmallerThan.tablet} {
    background-image: url("/assets/images/pages/contracts/landing/bg.svg");
  }

  ${screenLargerThan.tablet} {
    background-image: url("/assets/images/pages/contracts/landing/bg_desktop.svg");
    background-size: 60% auto;
    background-position: 40% 100%;
  }
`;

export const StyledIllustration = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: linear-gradient(
    40deg,
    rgba(95, 87, 255, 1) 0%,
    rgba(95, 87, 255, 1) 39%,
    rgba(57, 52, 153, 1) 100%
  );
  background-position: bottom center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 180px;

  ${screenSmallerThan.tablet} {
    background-image: url("/assets/images/pages/contracts/landing/bg.svg");
  }

  ${screenLargerThan.tablet} {
    background-image: url("/assets/images/pages/contracts/landing/bg_desktop.svg");
    background-size: 75% auto;
    background-position: 0 100%;
  }
`;

export const StyledBottomSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  background: var(--clr-background-0, #f8fafb);

  ${screenSmallerThan.tablet} {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }
`;

export const StyledFeaturesSection = styled.div`
  flex: 1;
  padding: 1rem 1.5rem;
  background: var(--clr-background-0, #f8fafb);

  hr {
    opacity: 0.25;
  }
`;

export const StyledCtaSection = styled.div`
  padding-inline: 1rem;
  padding-bottom: 1rem;
`;

export const StyledCtaButton = styled(Button)`
  display: block;
  font-weight: 600;
  font-size: 18px;
  width: 100%;

  ${screenSmallerThan.tablet} {
    margin-inline: auto;
  }

  ${screenLargerThan.tablet} {
    max-width: 23.75rem;
  }
`;

export const StyledLogo = styled(Logo)`
  filter: brightness(0) invert(1);
`;

export const StyledIllustrationBubble = styled.img`
  position: absolute;
  border-radius: 0.5rem;
  padding: 0.15rem 0.5rem;
  box-shadow: 0px 2.65px 2.12px 0px rgba(0, 0, 0, 0.02),
    0px 6.37px 5.09px 0px rgba(0, 0, 0, 0.03),
    0px 11.99px 9.59px 0px rgba(0, 0, 0, 0.04),
    0px 21.38px 17.11px 0px rgba(0, 0, 0, 0.04),
    0px 39.99px 32px 0px rgba(0, 0, 0, 0.05),
    0px 95.73px 76.58px 0px rgba(0, 0, 0, 0.07);

  ${screenSmallerThan.tablet} {
    max-width: calc(60vw - 3rem);
    background: var(--clr-background-primary-lighter, #ebf1ff);
    top: calc(50% + 3rem);
    left: calc(50% + 5rem);
    transform: translate(-50%, -50%);
  }

  ${screenLargerThan.tablet} {
    width: 100%;
    max-width: calc(50%);
    background: var(--clr-background-white, #fff);
    bottom: 25%;
    left: calc(25% + 3rem);
  }
`;

//---------------| DESKTOP

export const StyledDesktopContainer = styled.div`
  width: 100%;
  max-width: 82rem;
  margin-inline: auto;
  padding-inline: 1rem;
`;

export const StyledDesktopTopContainer = styled(StyledDesktopContainer)`
  display: flex;
  background: var(--clr-background-white, #fff);
`;

export const StyledDesktopIllustrationContainer = styled.div`
  position: relative;
  flex: 1;
  min-width: 22rem;
  max-width: 33.75rem;
  height: 21rem;
  background-color: var(--clr-primary-100, #5f57ff);

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100vw;
    right: calc(100% - 1rem);
    background-color: var(--clr-primary-100, #5f57ff);
  }
`;

export const StyledDesktopDetailsContainer = styled.div`
  flex: 2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 2rem;

  & > *:first-child {
    position: relative;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    ${screenSmallerThan.smallMonitor} {
      bottom: -1rem;
    }
  }

  ${screenLargerThan.smallMonitor} {
    padding: 1.5rem 4rem;
  }
`;

export const StyledDesktopCardsContainer = styled.div`
  margin-inline: -1.5rem;
`;
