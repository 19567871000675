import { useMemo } from "react";

import {
  getContractTotalValue,
  getNextActiveMilestone,
  isContractCompleted,
} from "@/services/ContractsService";
import { formatDate } from "@/utils/date";
import Icon from "@/components/misc/Icon";

import {
  StyledDateContainer,
  StyledDateField,
  StyledHighlightedLabel,
  StyledLabel,
  StyledProgressContainer,
  StyledStatusBar,
  StyledTopContainer,
  StyledValue,
} from "./StatusSection.styles";
import {
  calculateContractProgress,
  getContractStatusText,
  getNextDueDate,
  getPaymentStatusText,
} from "./StatusSection.utils";
import { useContractManagement } from "../../ContractManagementPage.context";
import BankFundingStatus from "../BankFundingStatus";

const StatusSection: React.FC<{ className?: string }> = ({ className }) => {
  const { contractDetails, paymentStatus } = useContractManagement();

  const {
    progressPercent,
    statusText,
    paidOutText,
    displayAmount,
    nextDueDateFormatted,
    nextDueDate,
    isCompleted,
  } = useMemo(() => {
    const progressPercent = calculateContractProgress(contractDetails);
    const statusText = getContractStatusText(contractDetails);
    const paidOutText = getPaymentStatusText(contractDetails);

    const { milestone } = getNextActiveMilestone(contractDetails);
    const isCompleted = isContractCompleted(contractDetails);
    const totalValue = getContractTotalValue(contractDetails);
    const displayAmount = isCompleted ? totalValue : milestone?.value || 0;

    const nextDueDate = getNextDueDate(contractDetails);
    const nextDueDateFormatted = nextDueDate ? formatDate(nextDueDate) : "";

    return {
      progressPercent,
      statusText,
      paidOutText,
      displayAmount,
      nextDueDateFormatted,
      nextDueDate,
      isCompleted,
    };
  }, [contractDetails]);

  const isBankAccountPayment = !!paymentStatus?.payment?.isBankAccountPayment;
  const paymentCompleted = !!paymentStatus?.payment?.paymentCompleted;

  if (isBankAccountPayment && !paymentCompleted) {
    return <BankFundingStatus />;
  }

  return (
    <div className={className}>
      <StyledTopContainer>
        <StyledProgressContainer>
          <StyledLabel>Status</StyledLabel>
          <StyledValue className="mt-2">{statusText}</StyledValue>
          <StyledStatusBar className="mt-3" $percent={progressPercent} />
        </StyledProgressContainer>

        {!!displayAmount ? (
          <div>
            <StyledLabel>Total Payment</StyledLabel>
            <StyledHighlightedLabel className="mt-1">
              {paidOutText ? `(${paidOutText})` : ""}
            </StyledHighlightedLabel>
            <StyledValue>${displayAmount}</StyledValue>
          </div>
        ) : (
          <div>
            <StyledLabel>Total funds</StyledLabel>
            <StyledHighlightedLabel className="mt-2">-</StyledHighlightedLabel>
            <StyledValue className="mt-1">-</StyledValue>
          </div>
        )}
      </StyledTopContainer>

      {!!nextDueDate && (
        <StyledDateContainer className="mt-4">
          <div>
            <StyledLabel>Next due date</StyledLabel>
            <StyledValue className="mt-2">{nextDueDateFormatted}</StyledValue>
          </div>

          {/* TO-CHECK */}
          {false && !isCompleted && (
            <StyledDateField
              disabled
              calendarType="single-date"
              prependContent={<Icon isSrcRelative src="edit.svg" size="sm" />}
              calendarProps={{
                type: "single-date",
                value: nextDueDate,
                onChange: () => {},
              }}
            />
          )}
        </StyledDateContainer>
      )}
    </div>
  );
};

export default StatusSection;
