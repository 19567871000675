import styled from "@emotion/styled";

import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { getViewPortHeightCssString } from "@/styles";

export const StyledLayout = styled(PageWithHeaderLayout)`
  min-height: ${getViewPortHeightCssString()};

  main {
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      flex-grow: unset;
    }
  }
`;
