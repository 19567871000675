import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { screenLargerThan } from "@/styles";
import { squareSizing } from "@/utils/css";

export const StyledHeading = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: #000;
`;

export const StyledContent = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  max-width: 64ch;
  color: #666d80;
  margin-inline: auto;

  ${screenLargerThan.tablet} {
    font-size: 1.25rem;
  }
`;

export const StyledImage = styled.img`
  ${squareSizing("7.5rem")};
`;

export const StyledContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.125rem;
  height: 100%;
  text-align: center;

  ${screenLargerThan.tablet} {
    gap: 1.5rem;
  }
`;

export const StyledButton = styled(Button)`
  display: block;
  padding-block: 1rem;
  border-radius: 10rem;
  width: 100%;
  max-width: 640px;
  margin: auto;
`;
