import { Route, Routes } from "react-router-dom";

import { SITE_PATHS } from "@/config/routing";
import ChatsPage from "@/features/pages/chat/ChatsPage";
import ProfileSettingsPage from "@/features/pages/user/ProfileSettingsPage";
import CreateContractPage from "@/features/pages/contract/CreateContractPage";
import HomePage from "@/features/pages/app/HomePage";
import ProfilePage from "@/features/pages/user/ProfilePage";
import EditProfilePage from "@/features/pages/user/EditProfilePage";
import ContractsListPage from "@/features/pages/contract/ContractsListPage";
import OfferListPage from "@/features/pages/contract/OfferListPage";
import Redirect from "@/components/navigation/Redirect";
import ActionsPage from "@/features/pages/user/ActionsPage";
import NotificationsPage from "@/features/pages/user/NotificationsPage";
import ContractRejectPage from "@/features/pages/contract/ContractRejectPage";
import TransactionsPage from "@/features/pages/contract/TransactionsPage";
import SubscriptionPage from "@/features/pages/user/SubscriptionPage";
import AppRatingPage from "@/features/pages/user/AppRatingPage";
import TestPage from "@/features/pages/debug/TestPage";
import PayoutOnboardingPage from "@/features/pages/app/PayoutOnboardingPage";
import ContractManagementPage from "@/features/pages/contract/ContractManagementPage";
import PreferencesSettingsPage from "@/features/pages/user/PreferencesSettingsPage";
import HeadlessAuthenticationPage from "@/features/pages/app/HeadlessAuthenticationPage";
import TermsAndConditionPage from "@/features/pages/app/TermsAndConditionPage";
import PolicyPage from "@/features/pages/app/PolicyPage";
import ServiceDetailsPage from "@/features/pages/user/ServiceDetailsPage";
import PortfolioDetailsPage from "@/features/pages/user/PortfolioDetailsPage";
import PublicPaypipeIdPage from "@/features/pages/user/PublicPaypipeIdPage";
import { appFeatures } from "@/config/services";
import { IS_PROD_ENV, IS_STAGING_ENV } from "@/config/app";
import ContactUserPage from "@/features/pages/user/ContactUserPage";
import ClaimIdPage from "@/features/pages/user/ClaimIdPageNew";

function AppRoutes() {
  const headlessAuthRoutesJsx = (
    <>
      <Route
        path={SITE_PATHS.HEADLESS_REGISTER_PAGE}
        element={<HeadlessAuthenticationPage authType="regsiter" />}
      />

      <Route
        path={SITE_PATHS.HEADLESS_LOGIN_PAGE}
        element={<HeadlessAuthenticationPage authType="login" />}
      />

      <Route
        path={SITE_PATHS.HEADLESS_LOGIN_PAGE_OLD}
        element={
          <Redirect keepSearch replace path={SITE_PATHS.HEADLESS_LOGIN_PAGE} />
        }
      />

      <Route
        path={SITE_PATHS.HEADLESS_REGISTER_PAGE_OLD}
        element={
          <Redirect
            keepSearch
            replace
            path={SITE_PATHS.HEADLESS_REGISTER_PAGE}
          />
        }
      />
    </>
  );

  const policyRoutesJsx = (
    <>
      <Route
        path={SITE_PATHS.TERMS_CONDITIONS_PAGE}
        element={<TermsAndConditionPage />}
      />

      <Route path={SITE_PATHS.POLICY_PAGE} element={<PolicyPage />} />
    </>
  );

  return (
    <Routes>
      <Route path={SITE_PATHS.CHAT_PAGE} element={<ChatsPage />} />

      {appFeatures.isSupported("CONTRACT.REJECT") && (
        <Route
          path={SITE_PATHS.CONTRACT_REJECT_PAGE}
          element={<ContractRejectPage />}
        />
      )}

      <Route
        path={SITE_PATHS.CREATE_CONTRACT_PAGE}
        element={<CreateContractPage />}
      />

      <Route
        path={SITE_PATHS.CONTRACT_MANAGE_PAGE}
        element={<ContractManagementPage />}
      />

      <Route path={SITE_PATHS.CONTRACTS_PAGE} element={<ContractsListPage />} />

      <Route path={SITE_PATHS.OFFERS_PAGE} element={<OfferListPage />} />

      <Route path={SITE_PATHS.ACTIONS_PAGE} element={<ActionsPage />} />

      <Route
        path={SITE_PATHS.TRANSACTION_PAGE}
        element={<TransactionsPage />}
      />

      {appFeatures.isSupported("SUBSCRIPTION") && (
        <>
          <Route
            path={SITE_PATHS.MANAGE_SUBSCRIPTION_PAGE}
            element={<SubscriptionPage />}
          />

          <Route
            path={SITE_PATHS.CANCEL_SUBSCRIPTION_PAGE}
            element={<SubscriptionPage />}
          />

          <Route
            path={SITE_PATHS.SUBSCRIPTION_PAGE}
            element={<SubscriptionPage />}
          />
        </>
      )}

      <Route
        path={SITE_PATHS.NOTIFICATIONS_PAGE}
        element={<NotificationsPage />}
      />

      <Route path={SITE_PATHS.APP_RATING_PAGE} element={<AppRatingPage />} />

      <Route path={SITE_PATHS.PROFILE_PAGE}>
        <Route
          path={`${SITE_PATHS.EDIT_PROFILE_PAGE}/:tab`}
          element={<EditProfilePage />}
        />
        <Route
          path={SITE_PATHS.EDIT_PROFILE_PAGE}
          element={<EditProfilePage />}
        />

        <Route
          path={SITE_PATHS.USER_SERVICE_DETAILS_PAGE}
          element={<ServiceDetailsPage />}
        />
        <Route
          path={SITE_PATHS.USER_PORTFOLIO_DETAILS_PAGE}
          element={<PortfolioDetailsPage />}
        />

        {IS_STAGING_ENV && (
          <>
            <Route
              path={`${SITE_PATHS.PROFILE_PAGE}/:tab`}
              element={<EditProfilePage />}
            />
            <Route index element={<EditProfilePage />} />
          </>
        )}

        {IS_PROD_ENV && (
          <>
            <Route
              path={`${SITE_PATHS.PROFILE_PAGE}/:tab`}
              element={<ProfilePage />}
            />
            <Route index element={<ProfilePage />} />
          </>
        )}
      </Route>

      <Route
        path={SITE_PATHS.SETTINGS_PAGE}
        element={<ProfileSettingsPage />}
      />

      <Route
        path={SITE_PATHS.PREFERENCES_SETTING_PAGE}
        element={<PreferencesSettingsPage />}
      />

      <Route
        path={SITE_PATHS.AUTH_REDIRECT_PAGE}
        element={<Redirect path={SITE_PATHS.HOME_PAGE} />}
      />

      <Route path={SITE_PATHS.HOME_PAGE} element={<HomePage />} />

      <Route
        path={SITE_PATHS.PAYOUT_ONBOARDING_PAGE}
        element={<PayoutOnboardingPage />}
      />

      {IS_STAGING_ENV && (
        <>
          <Route path={SITE_PATHS.DEV_PAGE} element={<TestPage />} />{" "}
          <Route
            path={`${SITE_PATHS.PAYPIPE_ID_PAGE}/:id`}
            element={<PublicPaypipeIdPage />}
          />
          <Route
            path={SITE_PATHS.PAYPIPE_ID_PAGE}
            element={<PublicPaypipeIdPage />}
          />
          <Route path={SITE_PATHS.CLAIM_ID_PAGE} element={<ClaimIdPage />} />
        </>
      )}

      {policyRoutesJsx}

      {headlessAuthRoutesJsx}

      {appFeatures.isSupported("CHAT.CONTACT_USER") && (
        <Route
          path={SITE_PATHS.CONTACT_USER_PAGE}
          element={<ContactUserPage />}
        />
      )}

      <Route path="*" element={<Redirect path={SITE_PATHS.HOME_PAGE} />} />
    </Routes>
  );
}

export default AppRoutes;
