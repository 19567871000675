import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { StreamChat } from "stream-chat";
import { ChannelList, Chat } from "stream-chat-react";

import { useAuth } from "@/services/Authentication";
import { getUserFullName } from "@/services/UserService";
import { chatService } from "@/config/services";
import ChatCard, { ChatCardLoading } from "@/features/chat/ChatCard";

import {
  ChatServiceContext as ChatServiceContextType,
  StreamClient,
} from "./ChatService.types";
import { STREAM_API_KEY } from "./ChatService.config";
import useChatSummary from "./hooks/useChatSummary";

const ChatServiceContext = createContext({} as ChatServiceContextType);

export const useChatService = () => useContext(ChatServiceContext);

const loadingChatListJsx = (
  <>
    <ChatCardLoading />
    <hr />
    <ChatCardLoading />
    <hr />
    <ChatCardLoading />
  </>
);

export const ChatServiceProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isAuthenticated, loggedInUserId, userData } = useAuth();
  const {
    data: { hasChatData },
    isLoading: isLoadingChatSummary,
  } = useChatSummary();

  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const idFromUrl = "";

  const streamClientRef = useRef<StreamClient>(null);
  const streamClient = streamClientRef.current;

  const tokenRef = useRef<string | null>(null);

  const isConnectingRef = useRef(isConnecting);
  isConnectingRef.current = isConnecting;

  //-----------------------

  const channelListJsx = useMemo(() => {
    return true ? null : streamClient ? (
      <ChannelList
        key="test"
        showChannelSearch
        allowNewMessagesFromUnfilteredChannels
        recoveryThrottleIntervalMs={100000}
        sendChannelsToList
        filters={{ members: { $in: [`${loggedInUserId}`] } }}
        sort={{ last_message_at: -1 }}
        options={{ limit: 2 }}
        Preview={(item) => {
          const { displayTitle = "", lastMessage, channel, unread } = item;
          const contractId = (channel?.cid || "").split("messaging:CHAT_")[1];
          const lastMessageDate = lastMessage?.updated_at
            ? new Date(lastMessage.updated_at)
            : undefined;

          if (!contractId || !displayTitle) {
            return null;
          }

          const isActive = contractId === idFromUrl;

          return (
            <Fragment>
              <ChatCard
                active={isActive}
                title={displayTitle}
                content={lastMessage?.text || ""}
                contractId={contractId}
                lastMessageDate={lastMessageDate}
                unreadMessageCount={unread || 0}
                username=""
                avatarImage=""
              />
              <hr />
            </Fragment>
          );
        }}
        LoadingIndicator={() => loadingChatListJsx}
      />
    ) : (
      loadingChatListJsx
    );
  }, [loggedInUserId, idFromUrl, streamClient]);

  //-----------------------

  const setToken = useCallback((token: string | null) => {
    tokenRef.current = token;
  }, []);

  const setStreamClient = useCallback((client: StreamClient) => {
    streamClientRef.current = client;
  }, []);

  const cleanUp = useCallback(() => {
    setToken(null);
    setStreamClient(null);
    setIsConnected(false);
  }, [setToken, setStreamClient]);

  const connectUser = useCallback(() => {
    if (!isAuthenticated || !userData || isConnecting || !!tokenRef.current) {
      return Promise.resolve(false);
    }

    setIsConnecting(true);
    isConnectingRef.current = true;

    return (async function () {
      try {
        let client: StreamClient = null;
        const chatToken = (await chatService.getToken()).token;

        if (chatToken) {
          setToken(chatToken);

          client = StreamChat.getInstance(STREAM_API_KEY);
          setStreamClient(client);
          setIsConnected(true);

          await client.connectUser(
            {
              id: `${userData.id}`,
              name: getUserFullName(userData),
              image: userData.owner_profile,
            },
            chatToken
          );

          return Promise.resolve(true);
        } else {
          setToken(null);
        }

        return Promise.resolve(false);
      } catch (err) {
        cleanUp();
        return Promise.resolve(false);
      } finally {
        setIsConnecting(false);
        isConnectingRef.current = false;
      }
    })();
  }, [
    isAuthenticated,
    userData,
    isConnecting,
    setToken,
    cleanUp,
    setStreamClient,
  ]);

  const disconnectUser = useCallback(() => {
    if (!streamClientRef.current) {
      cleanUp();
      return Promise.resolve(true);
    }

    try {
      (async function () {
        if (streamClientRef.current) {
          streamClientRef.current.disconnectUser();
        }
      })();

      cleanUp();

      return Promise.resolve(true);
    } catch {
      return Promise.resolve(false);
    }
  }, [cleanUp]);

  //-----------------------

  const connectUserRef = useRef(connectUser);
  connectUserRef.current = connectUser;
  const disconnectUserRef = useRef(disconnectUser);
  disconnectUserRef.current = disconnectUser;

  useEffect(() => {
    if (isLoadingChatSummary) {
      return;
    }

    if (hasChatData) {
      setIsConnecting(true);
      isConnectingRef.current = true;
      connectUserRef.current();
    } else {
      cleanUp();
      disconnectUserRef.current();
    }

    return () => {
      disconnectUserRef.current();
    };
  }, [hasChatData, isLoadingChatSummary, cleanUp]);

  //-----------------------

  return (
    <ChatServiceContext.Provider
      value={{
        isActive: isConnected,
        streamClient: streamClient || null,
        channelListJsx,
        connectUser,
        disconnectUser,
        isConnecting: isConnectingRef.current || isLoadingChatSummary,
      }}
    >
      {streamClient ? (
        <Chat client={streamClient} theme={`messaging`}>
          {children}
        </Chat>
      ) : (
        children
      )}
    </ChatServiceContext.Provider>
  );
};
