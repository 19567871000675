import {
  getViewPortHeightCssString,
  screenLargerThan,
  screenSmallerThan,
} from "@/styles";
import { squareSizing } from "@/utils/css";
import styled from "@emotion/styled";

export const StyledHeading = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: #000;
`;

export const StyledContent = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  max-width: 64ch;
  color: #666d80;
  margin-inline: auto;

  ${screenLargerThan.tablet} {
    font-size: 1.25rem;
  }
`;

export const StyledImage = styled.img`
  ${squareSizing("7.5rem")};
`;

export const StyledContainer = styled.div``;

export const StyledContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.125rem;
  height: 100%;
  padding-block: 7.5%;

  ${screenLargerThan.tablet} {
    gap: 1.5rem;
  }

  ${screenSmallerThan.tablet} {
    padding-block: 10%;
  }
`;

export const StyledPage = styled.div`
  display: flex;
  align-items: center;
  min-height: ${getViewPortHeightCssString()};
  text-align: center;
`;
