import { useMemo } from "react";
import styled from "@emotion/styled";

import { useWallet } from "@/services/UserService";
import { getCurrentMonth, getMonthList } from "@/utils/date";
import { screenLargerThan, useResponsive } from "@/styles";
import { joinClassNames } from "@/utils/classNames";

type Data = {
  month: number;
  monthName: string;
  value: string;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledBarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 0.65rem;
  flex: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: 2.5rem;
    width: 100%;
    height: 1px;
    background: #e9e9e9;
  }
`;

const StyledBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  flex: 1;
  font-size: 0.65rem;
  font-weight: 600;
  height: 5.25rem;
  transition: height 240ms ease-out;

  .bar {
    flex: 1;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: #efefef;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .month {
    padding: 0.5rem;
    border-radius: 10rem;
    text-align: center;
    width: 100%;
    min-height: 2rem;

    ${screenLargerThan.tablet} {
      min-width: 4rem;
    }
  }

  ${screenLargerThan.tablet} {
    font-size: 0.75rem;
    font-weight: 600;
  }

  &.has-value {
    height: 9rem;
    max-height: 100%;
  }

  &.active {
    .bar {
      background: var(--clr-primary-100, #5f57ff);
      color: #fff;
    }

    .month {
      background: #eeeefb;
      color: var(--clr-primary-100, #5f57ff);
    }
  }
`;

const EarningsGraph: React.FC<{ className?: string }> = ({ className }) => {
  const { balance } = useWallet();
  const { isScreenSmallerThanTablet } = useResponsive();

  const currentMonth = getCurrentMonth();
  const data = useMemo(() => {
    const months = getMonthList();
    const data: Data[] = (
      isScreenSmallerThanTablet ? [-1, 0, 1] : [-2, -1, 0, 1, 2]
    ).map((delta) => {
      let index = currentMonth + delta;
      if (index < 0) index += 12;
      index = index % months.length;

      return {
        month: index,
        monthName: months[index],
        value: delta === 0 ? (balance ? `${balance}` : "") : "",
      };
    });

    return data;
  }, [currentMonth, balance, isScreenSmallerThanTablet]);

  return (
    <StyledContainer className={className}>
      <StyledBarContainer>
        {data.map(({ month, value, monthName }, i) => (
          <StyledBar
            key={i}
            className={joinClassNames(
              month === currentMonth && "active",
              !!value && "has-value"
            )}
          >
            <div className="bar">{value ? `$${value}` : value}</div>
            <div className="month">{monthName}</div>
          </StyledBar>
        ))}
      </StyledBarContainer>
    </StyledContainer>
  );
};

export default EarningsGraph;
