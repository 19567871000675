"use client";

import React, { useEffect, useMemo, useState } from "react";

import { INITIAL_VALUES } from "@/features/contracts/ContractForm";
import {
  ContractCreationFlow,
  ContractCreatorType,
  ContractType,
  useCanCreateOffer,
} from "@/services/ContractsService";
import { useNavigate, usePathname, useSearchParams } from "@/services/Routing";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { useOnboardStripe } from "@/services/UserService";
import { useResponsive } from "@/styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import PayoutOnboardingUnderReviewPage from "@/features/pages/user/PayoutOnboardingUnderReviewPage";

import { StyledContractForm } from "./CreateContractPage.styles";

const CreateContractPage: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { searchParams } = useSearchParams();
  const { navigate } = useNavigate();
  const { pathname } = usePathname();
  const flowFormUrl = searchParams.get("flow");
  const flow = flowFormUrl ? (flowFormUrl as ContractCreationFlow) : undefined;
  const contractTypeFormUrl = searchParams.get("type");
  const contractCreatorTypeFormUrl = searchParams.get("creator");

  const { isReady } = useOnboardStripe();
  const { onboardingUnderReview, onboardingUnderReviewInitial } =
    useCanCreateOffer();

  const formInitialValues = useMemo(() => {
    const initialFormValue = {
      ...INITIAL_VALUES,
    };

    if (contractCreatorTypeFormUrl) {
      const CreatorType = contractCreatorTypeFormUrl as ContractCreatorType;
      if (
        [ContractCreatorType.Payer, ContractCreatorType.Payee].includes(
          CreatorType
        )
      ) {
        initialFormValue.created_as = CreatorType;
      }
    }

    if (flow || contractTypeFormUrl) {
      const contractType = (contractTypeFormUrl ||
        flow) as unknown as ContractType;
      if (
        [ContractType.OneTime, ContractType.Milestone].includes(contractType)
      ) {
        initialFormValue.contract_type = contractType;
      }
    }

    return initialFormValue;
  }, [flow, contractTypeFormUrl, contractCreatorTypeFormUrl]);

  const [formValues, setFormValues] = useState(formInitialValues);

  //-------------------

  useEffect(() => {
    if (flowFormUrl) {
      navigate(pathname, true);
    }
  }, [navigate, flowFormUrl, searchParams, pathname]);

  //-------------------

  const formJsx = (
    <StyledContractForm initialValues={formValues} onChange={setFormValues} />
  );

  if (!isReady) {
    return <SplashScreen />;
  }

  if (onboardingUnderReview) {
    return (
      <PayoutOnboardingUnderReviewPage
        showFirstTimeContent={onboardingUnderReviewInitial}
      />
    );
  }

  if (isScreenSmallerThanTablet) {
    return formJsx;
  }

  return (
    <DesktopPrimaryLayout showBackButton={false}>
      {formJsx}
    </DesktopPrimaryLayout>
  );
};

export default CreateContractPage;
