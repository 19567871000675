import React, { useMemo } from "react";

import {
  amIContractParticipant,
  getLoggedInUserContractUserType,
  useContractDetails,
} from "@/services/ContractsService";
import { joinClassNames } from "@/utils/classNames";
import { ANIMATION_CLASSNAMES } from "@/styles";

import { IContractTimelineProps } from "./ContractTimeline.types";
import TimelineItem from "./components/TimelineItem";
import { generateTimelineItems } from "./ContractTimeline.utils";
import { StyledTimeline } from "./ContractTimeline.styles";

const ContractTimeline: React.FC<IContractTimelineProps> = ({
  contractId,
  className,
  disableControls,
}) => {
  const { isLoading, contractDetails } = useContractDetails({
    contractId: contractId,
    redirectIfNotFound: true,
  });

  const { items: timelineItems, currentIndex } = useMemo(() => {
    if (!contractDetails || !amIContractParticipant(contractDetails)) {
      return { items: [], currentIndex: 0 };
    }

    const userType = getLoggedInUserContractUserType(contractDetails);

    if (!userType) {
      return { items: [], currentIndex: 0 };
    }

    const timelineData = generateTimelineItems({
      contract: contractDetails,
      userType,
      disableControls,
    });

    // if (disableControls) {
    //   timelineData.items = timelineData.items.map((item) => ({
    //     ...item,
    //     ctaButton: null,
    //     ctaAction: undefined,
    //     children
    //   }));
    // }

    return timelineData;
  }, [contractDetails, disableControls]);

  if (isLoading) {
    return null;
  }

  return (
    <StyledTimeline
      itemMargin="1.5rem"
      className={joinClassNames(
        ANIMATION_CLASSNAMES.FADE_IN_FROM_TOP,
        className
      )}
      currentIndex={currentIndex}
      items={timelineItems.map((item, i) => {
        return {
          children: (
            <TimelineItem
              key={item.id}
              active={currentIndex === i}
              disabled={i > currentIndex}
              {...item}
            />
          ),
          showCount: !item.isSubItem,
        };
      })}
    />
  );
};

export default ContractTimeline;
