"use client";

import React from "react";
import styled from "@emotion/styled/macro";

import { overflowEllipsis } from "@/styles";
import Button, { IButtonProps } from "@/components/input/Button";
import Icon from "@/components/misc/Icon";

const StyledTopSection = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  background: var(--clr-primary-100, #5f57ff);
  border-radius: inherit;
  padding: 1rem;
  padding-bottom: 1.5rem;
  color: #fff;

  & > *:last-child {
    flex-grow: 1;
    text-align: end;
    margin-top: 0.35rem;
  }
`;

const StyledCard = styled.div`
  background: #000000;
  border-radius: 0.5rem;
`;

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: -0.02em;
  ${overflowEllipsis()}
`;

const StyledSecondaryText = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.875rem;
`;

const SwitchButton = styled(Button)`
  width: 100%;
  padding-block: 0.75rem;
`;

const BalanceCard: React.FC<{
  className?: string;
  toggleButtonProps?: IButtonProps;
  title: string;
  value: string;
}> = ({ className, toggleButtonProps, title, value }) => {
  return (
    <StyledCard className={className}>
      <StyledTopSection>
        <Icon src="/assets/images/branding/logo_small.svg" />

        <div>
          <StyledSecondaryText>{title}</StyledSecondaryText>
          <StyledTitle className="mt-2">{value}</StyledTitle>
        </div>
      </StyledTopSection>

      {!!toggleButtonProps && (
        <SwitchButton
          {...toggleButtonProps}
          variant="ghost"
          colorVariant="white-primary"
        />
      )}
    </StyledCard>
  );
};

export default BalanceCard;
