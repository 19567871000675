"use client";

import React from "react";

import {
  StyledPage,
  StyledContentSection,
  StyledHeading,
  StyledImage,
  StyledSmallText,
  StyledSubHeading,
  StyledContainer,
} from "./Contract404Page.styles";

const Contract404Page: React.FC = () => {
  return (
    <StyledPage>
      <StyledContainer className="container">
        <StyledContentSection>
          <StyledSmallText>Offer not available!</StyledSmallText>
          <StyledHeading>Hey Buddy</StyledHeading>
          <StyledSubHeading>
            We can't seem to find the offer you are looking for.
          </StyledSubHeading>
        </StyledContentSection>

        <StyledImage src="/assets/pages/paypipe-id-404/ghost.png" alt="" />
      </StyledContainer>
    </StyledPage>
  );
};

export default Contract404Page;
