"use client";

import {
  overflowEllipsis,
  screenLargerThan,
  screenSmallerThan,
} from "@/styles";
import styled from "@emotion/styled";

export const StyledPage = styled.div`
  display: flex;
  gap: 0.25rem;
  position: relative;
  height: 100%;
  overflow: hidden;

  ${screenSmallerThan.smallMonitor} {
    flex-direction: column;
    background: #f9f9f9;
  }

  ${screenLargerThan.smallMonitor} {
    gap: 1rem;
  }
`;

export const SwitchSection = styled.div`
  ${screenSmallerThan.smallMonitor} {
    padding-inline: 1rem;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    background: #fff;
    padding-bottom: 1.5rem;
    box-shadow: 0px 4.01823px 6.69705px rgba(177, 177, 177, 0.08),
      0px 0.669705px 2.00912px rgba(0, 0, 0, 0.02);
  }

  ${screenLargerThan.smallMonitor} {
    min-width: 24rem;
  }
`;

export const StyledContentSection = styled.div`
  display: flex;
  gap: 1rem;
  flex-grow: 1;
  flex-direction: column;
  background: #f9f9f9;
  padding: 1rem;
  overflow: auto;
  max-width: 768px;

  ${screenLargerThan.tablet} {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  & > .container {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    max-width: 768px;
    margin-left: unset;
    padding: 0;
  }
`;

export const StyledCardsContainer = styled.div`
  flex-grow: 1;
  margin-inline: -1rem;
  padding-inline: 1rem;

  & > * {
    margin-bottom: 1rem;
  }

  ${screenLargerThan.tablet} {
    overflow: auto;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & > *:first-child {
    flex-grow: 1;
    ${overflowEllipsis()}
  }
`;

export const StyledNoData = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: #fff;
  border-radius: 0.75rem;
  color: rgba(164, 172, 185, 1);
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.5em;
  font-weight: 500;
`;
