import useSWR from "swr";
import { useMemo } from "react";

import { contractService } from "@/config/services";
import { getUrl } from "@/utils/string";

function usePaymentStatus(params: {
  stripeCheckoutSessionId?: string;
  milestoneId?: number;
}) {
  const { milestoneId, stripeCheckoutSessionId } = params;

  const cacheKey = useMemo(
    () =>
      getUrl({
        endpoint: "/contract/funding-status",
        queryParams: {
          session_id: params.stripeCheckoutSessionId,
          milestone_id: params.milestoneId,
        },
      }),
    [params]
  );

  const { data, isLoading } = useSWR(
    !!milestoneId || !!stripeCheckoutSessionId ? cacheKey : null,
    () => contractService.getPaymentStatus(params),
    {}
  );

  return {
    data,
    isLoading,
  };
}

export default usePaymentStatus;
