import { useMemo } from "react";

import Button from "@/components/input/Button";
import {
  getNextActiveMilestone,
  useSubmitContractMilestone,
} from "@/services/ContractsService";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body } from "@/components/Typography";
import styled from "@emotion/styled";

import { IRequestPaymentButtonProps } from "./RequestPaymentButton.types";

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

const RequestPaymentButton: React.FC<IRequestPaymentButtonProps> = ({
  contractDetails,
  ...restProps
}) => {
  const modalState = useModalState();

  const nextActiveMilestone = useMemo(
    () => getNextActiveMilestone(contractDetails).milestone,
    [contractDetails]
  );
  const { markAsDone: handleRequestPayment, isSubmitting, jsx } =
    useSubmitContractMilestone({
      contractId: contractDetails?.id || "",
      milestoneId: nextActiveMilestone?.id || 0,
    });

  const submitButtonText = isSubmitting
    ? "Requesting Payment..."
    : "Request Payment";

  return (
    <>
      {jsx}
      
      <Modal state={modalState} width="360px">
        <Body size="lg">Are you sure want to request payment?</Body>

        <StyledButtonsContainer className="mt-4">
          <Button
            variant="secondary"
            colorVariant="gray"
            onClick={modalState.close}
          >
            Cancel
          </Button>
          <Button disabled={isSubmitting} onClick={handleRequestPayment}>
            {submitButtonText}
          </Button>
        </StyledButtonsContainer>
      </Modal>

      <Button {...restProps} disabled={isSubmitting} onClick={handleRequestPayment}>
        {submitButtonText}
      </Button>
    </>
  );
};

export default RequestPaymentButton;
