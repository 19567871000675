import styled from "@emotion/styled";

import ContractWorkInput from "@/features/contracts/ContractWorkInput";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  min-height: 480px;
  overflow: hidden;
`;

export const StyledInput = styled(ContractWorkInput)`
  flex: 1;
  overflow: auto;
`;

export const StyledBottomSection = styled.div`
  padding: 1rem;
  border-top: 1px solid var(--clr-border-50, #eceff3);
`;

export const StyledContent = styled.p`
  font-size: 0.8125rem;
  line-height: 170%;
  color: var(--clr-text-900, #0d0d12);
  margin: 0;
  text-align: center;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 1.5rem;

  & > *:last-child {
    flex: 1;
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.75rem;
  color: #6b6f76;
  background: #f6f8fa;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  line-height: 170%;

  b {
    font-size: 0.925rem;
    font-weight: 700;
  }
`;
