import dayjs from "dayjs";

export function getFormattedDuration(durationInMs = 0) {
  const duration = dayjs
    .duration(Math.abs(-durationInMs), "millisecond")
    .format("D[_days]:H[_hours]:m[_minutes]:s[_seconds]");

  const durationParts = duration.split(":").reduce((map, item) => {
    const [value, key] = item.split("_");
    return { ...map, [key]: +(value || "0") };
  }, {}) as {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };

  const { days, hours, minutes, seconds } = durationParts;

  const formattedParts: string[] = [];

  if (days) {
    formattedParts.push(`${days} ${days > 1 ? "days" : "day"}`);
  }
  if (days || hours) {
    formattedParts.push(`${hours} ${hours > 1 ? "hours" : "hour"}`);
  }
  if (days || hours || minutes) {
    formattedParts.push(`${minutes} ${minutes > 1 ? "minutes" : "minute"}`);
  }
  if (seconds && !minutes && !formattedParts.length) {
    formattedParts.push("1 minute");
  }

  if (false) {
    if (days || hours || minutes || seconds) {
      formattedParts.push(`${seconds} ${seconds > 1 ? "seconds" : "second"}`);
    }
  }

  if (formattedParts.length === 1) {
    return formattedParts[0];
  }

  const lastPart = formattedParts.pop();
  let formatted = formattedParts.join(", ");
  if (lastPart) {
    formatted += ` and ${lastPart}`;
  }

  return formatted;
}
