import { IChatCardProps } from "@/features/chat/ChatCard";
import { BREAKPOINT_NAMES, DEVICE_BREAKPOINT } from "@/styles";
import { IS_STAGING_ENV } from "@/config/app";

import {
  USER_1_PROFILE,
  USER_2_PROFILE,
  USER_3_PROFILE,
} from "./ChatsPage.temp";

export const SHOW_FILTERS = IS_STAGING_ENV;

export const CHATS_LIST: IChatCardProps[] = [
  {
    contractId: "",
    avatarImage: USER_1_PROFILE,
    username: "Rakabuming Suhu",
    title: "Elfarma Website Redesign",
    content:
      "bro, kepriwe kie rawone ra mudun-mudun, selak kaliren weteng inyong..",
    hasAttachment: true,
    unreadMessageCount: 2,
    lastMessageDate: new Date(),
  },
  {
    contractId: "",
    username: "Jerome Bell",
    title: "Meeting with Anneth White",
    content:
      "bro, kepriwe kie rawone ra mudun-mudun, selak kaliren weteng inyong..",
    hasAttachment: true,
    lastMessageDate: new Date(),
  },
  {
    contractId: "",
    avatarImage: USER_2_PROFILE,
    username: "Jacob Jones",
    title: "Codedellaroute Project",
    content:
      "bro, kepriwe kie rawone ra mudun-mudun, selak kaliren weteng inyong..",
    lastMessageDate: new Date(),
  },
  {
    contractId: "",
    avatarImage: USER_3_PROFILE,
    username: "Esther Howard",
    title: "Al-Kautsar Branding",
    content:
      "bro, kepriwe kie rawone ra mudun-mudun, selak kaliren weteng inyong...",
    hasAttachment: true,
    lastMessageDate: new Date(),
  },
];

export const RESPONSIVE_BREAKPOINT = DEVICE_BREAKPOINT.smallMonitor;
export const RESPONSIVE_BREAKPOINT_NAME = "smallMonitor" as BREAKPOINT_NAMES;
