"use client";

import React, { useCallback } from "react";


import { useHash } from "@/services/Routing";
import { SITE_PATHS } from "@/config/routing";

import {
  StyledContentSection,
  StyledHeading,
  StyledImage,
  StyledContent,
  StyledButton,
} from "./PayoutOnboardingUnderReview.styles";

const PayoutOnboardingUnderReview: React.FC<{
  showFirstTimeContent?: boolean;
}> = ({ showFirstTimeContent }) => {
  const { removehash } = useHash();

  const handleCtaClick = useCallback(() => {
    removehash();
  }, [removehash]);

  if (showFirstTimeContent) {
    return (
      <StyledContentSection>
        <StyledImage src="/assets/pages/payout-onboarding/review.png" alt="" />
        <StyledHeading>Account Review in Progress</StyledHeading>
        <StyledContent>
          Thank you for completing the onboarding process! To ensure the
          security and integrity of our platform, we need to review your account
          before you can start creating offers.
        </StyledContent>
        <StyledContent>
          Our team will review your account as soon as possible. You will
          receive an email notification once your account is approved and you
          are able to create offers.
        </StyledContent>
        <StyledContent>
          We appreciate your patience and understanding.
        </StyledContent>
      </StyledContentSection>
    );
  }

  return (
    <StyledContentSection>
      <StyledImage src="/assets/pages/payout-onboarding/review.png" alt="" />
      <StyledHeading>Account Under Review</StyledHeading>
      <StyledContent>
        Your account is currently under review. We will notify you via email
        once you are approved to create offers
      </StyledContent>
      <StyledContent>Please check your email for updates.</StyledContent>
      <StyledButton className="mt-5" onClick={handleCtaClick} link={SITE_PATHS.HOME_PAGE}>I understand</StyledButton>
    </StyledContentSection>
  );
};

export default PayoutOnboardingUnderReview;
