"use client";

import React, { Fragment, memo, useCallback, useMemo } from "react";
import {
  ChannelList,
  ChannelPreviewUIComponentProps,
  DefaultStreamChatGenerics,
} from "stream-chat-react";
import styled from "@emotion/styled";
import lodashGet from "lodash/get";
import { Channel, ChannelSortBase } from "stream-chat";

import ChatCard, { ChatCardLoading } from "@/features/chat/ChatCard";
import { useAuth } from "@/services/Authentication";
import { useContractUrlId } from "@/services/ContractsService";
import ErrorBoundary from "@/components/input/ErrorBoundary";
import {
  CHANNEL_TYPE_FILTER_URL_KEY,
  ChannelType,
  isUserChannel,
  SHOW_SENT_CHAT_REQUESTS,
  useChatService,
} from "@/services/ChatServiceNew";
import { useParamState } from "@/services/Routing";
import { removeDuplicatesObjects } from "@/utils/array";

import { StyledChatList } from "../ChatsPage.styles";
import NoChatsComponent from "./NoChats";
import { SHOW_FILTERS } from "../ChatsPage.config";

const loadingChatListJsx = (
  <>
    <ChatCardLoading />
    <hr />
    <ChatCardLoading />
    <hr />
    <ChatCardLoading />
  </>
);

const getLoadingChatList = () => loadingChatListJsx;

const SORT = {
  last_message_at: -1,
} satisfies ChannelSortBase<DefaultStreamChatGenerics>;

const StyledNoChatsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoChats = memo(({ channelType }: { channelType?: ChannelType }) => (
  <StyledNoChatsContainer>
    <NoChatsComponent
      title={
        channelType === ChannelType.ChatRequest && (
          <>You don&apos;t have any requests.</>
        )
      }
    />
  </StyledNoChatsContainer>
));

const ChatList: React.FC = () => {
  const { loggedInUserId } = useAuth();
  const { idFromUrl } = useContractUrlId();
  const { isActive, isConnecting } = useChatService();
  const [channelTypeFilter] = useParamState<string>(
    CHANNEL_TYPE_FILTER_URL_KEY,
    "",
    {
      parseJson: false,
    }
  );

  const previewFunction = useCallback(
    (item: ChannelPreviewUIComponentProps<DefaultStreamChatGenerics>) => {
      const { displayTitle = "", lastMessage, channel, unread } = item;
      const contractId = (channel?.cid || "").split("messaging:CHAT_")[1];
      const lastMessageDate = lastMessage?.updated_at
        ? new Date(lastMessage.updated_at)
        : undefined;

      const channelId = channel?.cid;

      const hasAttachments =
        !!lastMessage?.attachments?.length ||
        (channel?.state?.messageSets).some((set) =>
          set.messages.some((message) => !!message.attachments?.length)
        );

      const channelType = lodashGet(
        channel,
        "data.extraData.channelType",
        ""
      ) as ChannelType;

      if ((!contractId || !displayTitle) && !isUserChannel({ channelId })) {
        return null;
      }

      const isActive = contractId === idFromUrl;

      return (
        <Fragment key={channelId}>
          <ChatCard
            active={isActive}
            title={displayTitle}
            content={lastMessage?.text || ""}
            contractId={contractId}
            lastMessageDate={lastMessageDate}
            unreadMessageCount={unread || 0}
            username=""
            avatarImage=""
            hasAttachment={hasAttachments}
            channel_id={channelId}
            channelType={channelType}
            extraData={channel?.data?.extraData || {}}
          />
          <hr />
        </Fragment>
      );
    },
    [idFromUrl]
  );

  const channelFiltersFunction = useCallback(
    (channels: Channel<DefaultStreamChatGenerics>[]) => {
      if (channelTypeFilter) {
        let filtered = SHOW_FILTERS
          ? channels.filter((item) => {
              const { data, cid: channelId } = item;
              const channelType = lodashGet(data, "extraData.channelType", "");
              const requestUserId = lodashGet(
                data,
                "extraData.requestUserId",
                0
              );
              const accepterUserId = lodashGet(
                data,
                "extraData.accepterUserId",
                0
              );
              const channelName = lodashGet(data, "name", "");
              const memberCount = lodashGet(data, "member_count", 0);
              const contractId = (channelId || "").split("messaging:CHAT_")[1];

              switch (channelTypeFilter) {
                case ChannelType.ChatRequest:
                  if (channelType !== ChannelType.ChatRequest) {
                    return false;
                  }

                  if (!requestUserId) {
                    return false;
                  }

                  if (SHOW_SENT_CHAT_REQUESTS) {
                    return true;
                  }

                  return loggedInUserId === accepterUserId;

                case ChannelType.Contract:
                  if (isUserChannel({ channelId })) {
                    return false;
                  }

                  if (memberCount < 2) {
                    return false;
                  }

                  if (!channelName) {
                    return false;
                  }

                  if (!contractId) {
                    return false;
                  }

                  return true;

                case ChannelType.PersonalChat:
                  if (channelType === ChannelType.ChatRequest) {
                    return false;
                  }

                  if (!isUserChannel({ channelId })) {
                    return false;
                  }

                  return true;

                default:
                  return true;
              }
            })
          : channels.filter(ch => !!ch.data?.last_message_at);


        filtered = removeDuplicatesObjects(filtered, "cid");

        return filtered;
      }

      return channels;
    },
    [channelTypeFilter, loggedInUserId]
  );

  const filters = useMemo(
    () => ({ members: { $in: [`${loggedInUserId}`] } }),
    [loggedInUserId]
  );

  const render = useCallback(
    () => (
      <ChannelList
        key="test"
        showChannelSearch
        allowNewMessagesFromUnfilteredChannels
        recoveryThrottleIntervalMs={100000}
        sendChannelsToList
        filters={filters}
        sort={SORT}
        LoadingIndicator={getLoadingChatList}
        Preview={previewFunction}
        EmptyStateIndicator={() => (
          <NoChats channelType={channelTypeFilter as ChannelType} />
        )}
        channelRenderFilterFn={channelFiltersFunction}
      />
    ),
    [previewFunction, filters, channelFiltersFunction, channelTypeFilter]
  );

  //---------------------

  if (isConnecting) {
    return loadingChatListJsx;
  }

  if (!isActive) {
    return (
      <StyledNoChatsContainer>
        <NoChats channelType={channelTypeFilter as ChannelType} />
      </StyledNoChatsContainer>
    );
  }

  return (
    <StyledChatList className="chat-list">
      <ErrorBoundary fallback={loadingChatListJsx}>{render()}</ErrorBoundary>
    </StyledChatList>
  );
};

export default ChatList;
