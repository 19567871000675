"use client";

import React from "react";

import {
  StyledPage,
  StyledContentSection,
  StyledHeading,
  StyledImage,
  StyledContainer,
  StyledContent,
} from "./UserBlockedPage.styles";

const UserBlockedPage: React.FC = () => {
  return (
    <StyledPage>
      <StyledContainer className="container">
        <StyledContentSection>
          <StyledImage src="/assets/pages/blocked/blocked.png" alt="" />
          <StyledHeading>Account Suspended</StyledHeading>
          <StyledContent>
            Your account has been temporarily suspended due to unusual activity.
            Our team is reviewing the matter and will reach out to you if
            additional information is required.
          </StyledContent>
        </StyledContentSection>
      </StyledContainer>
    </StyledPage>
  );
};

export default UserBlockedPage;
