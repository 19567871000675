import { useCallback } from "react";

import { useOnboardStripe } from "@/services/UserService";
import { useAuth } from "@/services/Authentication";
import { useSearchParams } from "@/services/Routing";
import Modal, { useModalState } from "@/components/misc/Modal";
import PayoutOnboardingUnderReview from "@/features/user/PayoutOnboardingUnderReview";

function useCanCreateOffer() {
  const { userData } = useAuth();
  const { searchParams } = useSearchParams();
  const { isOnboarded } = useOnboardStripe();
  const modalState = useModalState({ urlHash: "#account-under-review" });

  const onboardingSuccess = searchParams.get("onboarding") === "submitted";
  const canCreateOffer = !!userData?.can_create_offer;
  const onboardingUnderReview = !canCreateOffer && isOnboarded;
  const onboardingUnderReviewInitial =
    onboardingUnderReview && onboardingSuccess;

  const jsx = onboardingUnderReview && (
    <Modal showCloseButton state={modalState} width="560px">
      <PayoutOnboardingUnderReview
        showFirstTimeContent={onboardingUnderReviewInitial}
      />
    </Modal>
  );

  //-----------------------

  const onCreateOffer = useCallback(
    (e: any) => {
      if (onboardingUnderReview) {
        if (e.preventDefault) e.preventDefault();
        if (e.stopPropagation) e.stopPropagation();
        modalState.open();
      }
    },
    [modalState, onboardingUnderReview]
  );

  //-----------------------

  return {
    canCreateOffer,
    onboardingUnderReview,
    onboardingUnderReviewInitial,
    jsx,
    onCreateOffer,
  };
}

export default useCanCreateOffer;
