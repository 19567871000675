import { IPageWithHeaderLayoutProps } from "@/features/layouts/PageWithHeaderLayout";
import { ContractCompleteDetails, PaymentStatus } from "@/services/ContractsService";

export enum Tab {
  Overview = "Overview",
  Details = "Details",
}

export interface IContractManagementPageProps {
  layoutProps?: IPageWithHeaderLayoutProps;
}

export interface IContractManagementContext {
  contractDetails: ContractCompleteDetails;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  paymentStatus: PaymentStatus | null;
  disableControls: boolean;
  layoutProps?: IPageWithHeaderLayoutProps;
}
