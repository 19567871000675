"use client";

import React from "react";

import { SITE_PATHS } from "@/config/routing";
import PayoutOnboardingUnderReview from "@/features/user/PayoutOnboardingUnderReview";

import { StyledLayout } from "./PayoutOnboardingUnderReviewPage.styles";

const PayoutOnboardingUnderReviewPage: React.FC<{
  showFirstTimeContent?: boolean;
}> = ({ showFirstTimeContent }) => {
  return (
    <StyledLayout
      headerProps={{
        backButtonProps: {
          link: SITE_PATHS.HOME_PAGE,
        },
      }}
    >
      <div className="container">
        <PayoutOnboardingUnderReview
          showFirstTimeContent={showFirstTimeContent}
        />
      </div>
    </StyledLayout>
  );
};

export default PayoutOnboardingUnderReviewPage;
