import Button from "@/components/input/Button";
import { SITE_PATHS } from "@/config/routing";
import { useCanCreateOffer } from "@/services/ContractsService";

const NewOfferButton: React.FC = () => {
  const { onCreateOffer, jsx, onboardingUnderReview } = useCanCreateOffer();

  return (
    <>
      {jsx}
      <Button
        onClick={onCreateOffer}
        link={onboardingUnderReview ? "" : SITE_PATHS.CREATE_CONTRACT_PAGE}
      >
        Send a new offer
      </Button>
    </>
  );
};

export default NewOfferButton;
