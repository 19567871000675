import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Notification } from "@/services/ChatService";
import { PayoutOnboardStatus, Transcation } from "@/services/UserService";

import { AppState } from "../index";
import { listStateSetter } from "../utils";

export interface UserState {
  isStripeOnboarded: boolean;
  loadingIsStripeOnboarded: boolean;
  notifications: Notification[];
  onboardStatus: PayoutOnboardStatus | null;
  transactions: Transcation[];
}

const initialState: UserState = {
  isStripeOnboarded: false,
  loadingIsStripeOnboarded: true,
  notifications: [],
  onboardStatus: null,
  transactions: [],
};

export const userSlice = createSlice({
  name: "USER",
  initialState,
  reducers: {
    setOnboardedState: (
      state,
      action: PayloadAction<{
        isOnboarded?: boolean;
        isLoading?: boolean;
        onboardStatus?: PayoutOnboardStatus;
      }>
    ) => {
      const {
        isLoading = null,
        isOnboarded = null,
        onboardStatus = null,
      } = action.payload;

      if (isLoading !== null) {
        state.loadingIsStripeOnboarded = isLoading;
      }

      if (isOnboarded !== null) {
        state.isStripeOnboarded = isOnboarded;
      }

      if (onboardStatus !== null) {
        state.onboardStatus = onboardStatus;
      }
    },

    setNotitications: (
      state,
      action: PayloadAction<{
        notifications?: Notification[];
      }>
    ) => {
      const { notifications = [] } = action.payload;

      state.notifications = listStateSetter(state.notifications, notifications);
    },

    //--------------------------------

    setTransactions: (
      state,
      action: PayloadAction<{
        transactions?: Transcation[];
      }>
    ) => {
      const { transactions = [] } = action.payload;

      state.transactions = listStateSetter(state.transactions, transactions);
    },
  },
});

export const selectIsStripeOnboarded = (state: AppState) =>
  state.user.isStripeOnboarded;
export const selectLoadingIsStripeOnboarded = (state: AppState) =>
  state.user.loadingIsStripeOnboarded;

export const selectStripeOnboardingStatus = (state: AppState) =>
  state.user.onboardStatus;

export const selectNotifications = (state: AppState) =>
  state.user.notifications;

export const selectUnreadNotifications = createSelector(
  (state: AppState) => state,
  ({ user }) => {
    const now = new Date();

    return user.notifications.filter((notification) => {
      if (!notification.timestamp) {
        return false;
      }

      const dif = now.getTime() - new Date(+notification.timestamp).getTime();
      const Seconds_from_T1_to_T2 = dif / 1000;
      const diffInSeconds = Math.abs(Seconds_from_T1_to_T2);

      return diffInSeconds < 43200; //12 hours;
    });
  }
);

export const selectTrasactions = (state: AppState) => state.user.transactions;

export const selectTrasaction = (transactionId: string) => (state: AppState) =>
  state.user.transactions.find((t) => t.id === transactionId);

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
