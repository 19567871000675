import React from "react";
import lodashGet from "lodash/get";

import ImageTitleContent from "@/components/data/ImageTitleContent";
import Icon from "@/components/misc/Icon";
import {
  ContractCreatorType,
  getContractManagePageUrl,
  PaymentStatus,
  useManageContract,
} from "@/services/ContractsService";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { useAuth } from "@/services/Authentication";
import { useNavigate } from "@/services/Routing";

import {
  StyledBottomContainer,
  StyledButton,
  StyledChatButton,
  StyledContainer,
  StyledTopContainer,
} from "./ContractPaymentSuccessPage.styles";

const ContractPaymentSuccessPage: React.FC<{
  email?: string;
  paymentStatus?: PaymentStatus;
}> = ({ email, paymentStatus }) => {
  const {
    contractDetails,
    isLoading: isLoadingContractDetails,
    contractId,
  } = useManageContract({
    redirectIfNotFound: true,
    useContractIdFromUrl: true,
  });
  const { login, isAuthenticated } = useAuth();
  const { navigate } = useNavigate();

  if (isLoadingContractDetails) {
    return <SplashScreen />;
  }

  const milestone1Name = lodashGet(contractDetails, "milestones[0].name", "");
  const contractUrl = getContractManagePageUrl(contractId);

  const successJsx = (
    <StyledContainer className="container">
      <StyledTopContainer>
        <ImageTitleContent
          title="Payment Received!"
          content={
            <>
              You have successfully funded
              {milestone1Name ? ` ${milestone1Name}` : ""}.
            </>
          }
          image="/assets/images/pages/contracts/payment_success.svg"
        />
      </StyledTopContainer>

      <StyledBottomContainer>
        {isAuthenticated ? (
          <StyledButton
            link={contractUrl}
            onClick={(e) => {
              e.preventDefault();
              navigate(contractUrl, true);
            }}
          >
            View Job <Icon isSrcRelative src="share.svg" size="xxs" />
          </StyledButton>
        ) : (
          <StyledButton
            onClick={() => {
              login({ redirectUrl: contractUrl, email });
            }}
          >
            View Job <Icon isSrcRelative src="share.svg" size="xxs" />
          </StyledButton>
        )}

        <StyledChatButton
          contractId={contractId}
          variant="ghost"
          userType={ContractCreatorType.Payee}
          onboardingEmail={email}
        />
      </StyledBottomContainer>
    </StyledContainer>
  );

  const inProgressJsx = (
    <StyledContainer className="container">
      <StyledTopContainer>
        <ImageTitleContent
          title="Funding in Progress"
          content={
            <>
              You've paid using bank transfer which take some time to complete.
              We'll notify you and the service provider when the payment is
              completed and the job offer is marked as funded.
            </>
          }
          image="/assets/images/pages/contracts/payment_in_progress.svg"
        />
      </StyledTopContainer>

      <StyledBottomContainer>
        <StyledButton
          link={contractUrl}
          onClick={(e) => {
            e.preventDefault();
            navigate(contractUrl, true);
          }}
        >
          I understand
        </StyledButton>
      </StyledBottomContainer>
    </StyledContainer>
  );

  if (paymentStatus) {
    const {
      payment: { paymentCompleted, isBankAccountPayment },
    } = paymentStatus;

    if (!paymentCompleted && isBankAccountPayment) {
      return inProgressJsx;
    }

    return successJsx;
  }

  return successJsx;
};

export default ContractPaymentSuccessPage;
