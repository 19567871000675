"use client";

import React, { useMemo } from "react";

import AppTabBar from "@/components/navigation/TabBar";
import CreateContractDrawer from "@/features/contracts/CreateContractDrawer";
import { selectHasContracts, useAppSelector } from "@/services/Store";
import { usePathname } from "@/services/Routing";
import { useDrawerState } from "@/components/misc/Drawer";
import { useCanCreateOffer } from "@/services/ContractsService";

import {
  CREATE_OFFER_ITEM_INDEX,
  TAB_BAR_ITEMS,
} from "./AppPrimaryTabBar.config";
import NotificationItem from "./components/NotificationItem";
import ChatItem from "./components/ChatItem";

const AppPrimaryTabBar: React.FC<{ className?: string }> = ({ className }) => {
  const { pathname } = usePathname();

  const drawerState = useDrawerState();
  const hasContracts = useAppSelector(selectHasContracts);
  const {
    onCreateOffer,
    jsx: createOfferJsx,
    onboardingUnderReview,
  } = useCanCreateOffer();

  const tabBarItems = useMemo(() => {
    const items = [...TAB_BAR_ITEMS].map((item) => ({
      ...item,
      active: pathname === item.link,
    }));

    if (hasContracts) {
      items[2].onClick = drawerState.open;
      items[2].link = "";
    }

    const notificationItem = TAB_BAR_ITEMS[4];
    notificationItem.render = ({ iconJsx, titleJsx, renderItemComponent }) =>
      renderItemComponent({
        children: (
          <>
            <NotificationItem>{iconJsx}</NotificationItem>
            {titleJsx}
          </>
        ),
      });

    const chatItem = TAB_BAR_ITEMS[3];
    chatItem.render = ({ iconJsx, titleJsx, renderItemComponent }) =>
      renderItemComponent({
        children: (
          <>
            <ChatItem>{iconJsx}</ChatItem>
            {titleJsx}
          </>
        ),
      });

    if (onboardingUnderReview) {
      TAB_BAR_ITEMS[CREATE_OFFER_ITEM_INDEX].link = "";
      TAB_BAR_ITEMS[CREATE_OFFER_ITEM_INDEX].onClick = onCreateOffer;
    }

    return items;
  }, [
    pathname,
    drawerState,
    hasContracts,
    onCreateOffer,
    onboardingUnderReview,
  ]);

  return (
    <>
      {createOfferJsx}

      <AppTabBar className={className} items={tabBarItems} />

      <CreateContractDrawer state={drawerState} />
    </>
  );
};

export default AppPrimaryTabBar;
