import React, { useCallback } from "react";

import Modal, { useModalState } from "@/components/misc/Modal";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import Icon from "@/components/misc/Icon";
import Button from "@/components/input/Button";
import {
  useContractDetails,
  useReleasePaymentStatus,
  useSubmitContractMilestone,
} from "@/services/ContractsService";

import { StyledButtomSmall } from "../../ContractTimeline.styles";
import {
  StyledBottomSection,
  StyledContainer,
  StyledContent,
  StyledInfo,
  StyledInput,
} from "./SubmitWorkButton.styles";

interface ISubmitWorkButtonProps {
  contractId: string;
  milestoneId: number;
  className?: string;
}

const SubmitWorkButton: React.FC<ISubmitWorkButtonProps> = ({
  contractId,
  milestoneId,
  className,
}) => {
  const { reload: reloadContractDetails } = useContractDetails({ contractId });
  const modalState = useModalState();

  const { canReleaseFund, remainingTimeFormatted } = useReleasePaymentStatus({
    contractId,
    milestoneId,
  });

  const { submit, isSubmitting, jsx, work, setWork } =
    useSubmitContractMilestone({
      contractId,
      milestoneId,
    });

  const handleSubmit = useCallback(() => {
    submit()?.then(() => {
      modalState.close();
      reloadContractDetails();
    });
  }, [submit, modalState, reloadContractDetails]);

  const waitInfoJsx = (
    <StyledInfo>
      <Icon isSrcRelative src="info_2.svg" colorVariant="gray" size="sm" />
      <div>
        This is not a payment request, you can request payment after{" "}
        <b>{remainingTimeFormatted}</b>.
      </div>
    </StyledInfo>
  );

  return (
    <>
      {jsx}

      <StyledButtomSmall
        disabled={isSubmitting}
        className={className}
        onClick={modalState.open}
      >
        Submit Work
      </StyledButtomSmall>

      <Modal
        state={modalState}
        mobileProps={{ fullscreen: true, className: "p-0" }}
      >
        <PageWithHeaderLayout
          headerProps={{
            backButtonProps: {
              onClick: modalState.close,
              children: <Icon isSrcRelative src="cross.svg" size="xxs" />,
              style: { background: "none" },
            },
            titleProps: {
              children: "Submit Your Work",
            },
          }}
        >
          <StyledContainer>
            {!canReleaseFund && waitInfoJsx}

            <StyledInput value={work} onChange={setWork} />

            <StyledBottomSection>
              <StyledContent>
                By submitting this work you are accepting that the client may
                have revisions before this job is completed.
              </StyledContent>

              <Button
                className="w-100 mt-3"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSubmitting || !work.content}
              >
                Submit Work
              </Button>
            </StyledBottomSection>
          </StyledContainer>
        </PageWithHeaderLayout>
      </Modal>
    </>
  );
};

export default SubmitWorkButton;
