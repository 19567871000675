"use client";

import React from "react";
import styled from "@emotion/styled";
import Icon from "@/components/misc/Icon";

const StyledSection = styled.div`
  background: rgba(248, 248, 248, 1);
  padding: 1rem;
  border-radius: 0.75rem;

  ul {
    color: rgba(102, 109, 128, 1);
    list-style: disc;
    margin-left: 1rem;
    margin-top: 0.75rem;

    li {
      font-size: 0.875rem;
      font-weight: 400;
      margin-top: 0.5rem;
    }
  }
`;

const StyledSectionHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #000;
  font-size: 1.125rem;
  font-weight: 600;
`;

const StyledHeading = styled.div`
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: -0.125rem;
`;

const DescripitonTip: React.FC = () => {
  return (
    <div>
      <StyledHeading>
        Keep this in mind when writing descriptions!
      </StyledHeading>

      <StyledSection className="mt-4">
        <StyledSectionHeading>
          <Icon isSrcRelative src="clear_content.svg" size="sm" />
          Be Thorough and Clear
        </StyledSectionHeading>
        <ul>
          <li>Clearly outline every deliverable, milestone, and clause.</li>
          <li>
            A detailed description ensures smoother collaboration and quicker
            dispute resolution by the Paypipe team.
          </li>
        </ul>
      </StyledSection>

      <StyledSection className="mt-3">
        <StyledSectionHeading>
          <Icon isSrcRelative src="attached_files.svg" size="sm" />
          Attach supporting files
        </StyledSectionHeading>
        <ul>
          <li>
            Upload important documents such as contracts, project briefs, or
            additional references.
          </li>
          <li>
            These files help provide clarity and prevent misunderstandings.
          </li>
        </ul>
      </StyledSection>
    </div>
  );
};

export default DescripitonTip;
