"use client";
import React from "react";

import ContractTimeline from "@/features/contracts/ContractTimeline";

import { useContractManagement } from "../ContractManagementPage.context";

const OverviewSection: React.FC<{ className?: string }> = ({ className }) => {
  const { contractDetails, disableControls } = useContractManagement();

  return (
    <ContractTimeline
      contractId={contractDetails.id}
      className={className}
      disableControls={disableControls}
    />
  );
};

export default OverviewSection;
