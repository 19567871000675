"use client";

import React from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import { SITE_PATHS } from "@/config/routing";
import { useCanCreateOffer } from "@/services/ContractsService";

const CreateOfferButton: React.FC<IButtonProps> = (props) => {
  const { onCreateOffer, jsx, onboardingUnderReview } = useCanCreateOffer();

  return (
    <>
      {jsx}
      <Button
        {...props}
        onClick={onCreateOffer}
        link={onboardingUnderReview ? "" : SITE_PATHS.CREATE_CONTRACT_PAGE}
      >
        Create Offer +
      </Button>
    </>
  );
};

export default CreateOfferButton;
