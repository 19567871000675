"use client";

import React from "react";
import styled from "@emotion/styled/macro";
import lodashGet from "lodash/get";

import {
  PayoutStatus,
  TranscationType,
  useRequestPayout,
} from "@/services/UserService";
import { overflowEllipsis } from "@/styles";
import Icon from "@/components/misc/Icon";
import { squareSizing } from "@/utils/css";
import Button from "@/components/input/Button";
import { selectTrasaction, useAppSelector } from "@/services/Store";
import { formatDate } from "@/utils/date";

import TransactionStatus from "./TransactionStatus";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { Body } from "@/components/Typography";
import Modal, { useModalState } from "@/components/misc/Modal";

const StyledCard = styled.div`
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
`;

const StyledPrimaryText = styled.div`
  font-weight: 600;
  font-size: 0.95rem;
  color: #0d0d12;
  letter-spacing: -0.02em;
`;

const StyledTitle = styled(StyledPrimaryText)`
  ${overflowEllipsis()}
`;

const StyledSecondaryText = styled.div`
  font-weight: 500;
  font-size: 0.8125rem;
  color: #818898;
`;

const StyledValue = styled.div`
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background: #f6f8fa;
  border: 1px solid #eceff3;
  border-radius: 0.25rem;
  min-width: 4rem;
  text-align: center;

  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #000000;
`;

const StyledSpendingValue = styled.div`
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #dc2626;
  margin-left: auto;
`;

const StyledTopSection = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  & > *:first-child {
    flex-grow: 1;
    overflow: hidden;
  }
`;

const StyledButton = styled(Button)`
  gap: 0.5rem;
  font-weight: 500;
  font-size: 0.815rem;
  padding-block: 0.35rem;
`;

const StyledIconContainer = styled.div`
  ${squareSizing("3rem")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f6f8fa;
  border-radius: 10rem;
`;

const StyledSpendingTopSection = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;

  & > *:last-child {
    flex-grow: 1;
  }
`;

const StyledBottomSection = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: rgba(246, 248, 250, 1);
  border-radius: 0.75rem;
  color: rgba(164, 172, 185, 1);
  padding: 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5em;
  font-weight: 500;
`;

const RequestPayoutButton: React.FC<{
  milestoneId: number;
  className?: string;
}> = ({ milestoneId, className }) => {
  const {
    isLoading,
    request,
    requested,
    jsx: requestPayoutJsx,
  } = useRequestPayout({ milestoneId });
  const modalState = useModalState();

  if (requested) {
    return null;
  }

  if (isLoading) {
    return <SplashScreen />;
  }

  const confirmationJsx = (
    <>
      <Body size="lg" className="text-center">
        Are you sure want to request payout?
      </Body>
      <StyledButtonsContainer className="mt-4">
        <Button
          variant="secondary"
          colorVariant="gray"
          onClick={modalState.close}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => {
            modalState.close();
            request();
          }}
        >
          Request Payout
        </Button>
      </StyledButtonsContainer>
    </>
  );

  return (
    <>
      {requestPayoutJsx}

      <Modal state={modalState} width="360px">
        {confirmationJsx}
      </Modal>

      <Button
        className={className}
        disabled={isLoading}
        onClick={modalState.open}
      >
        Request Payout
      </Button>
    </>
  );
};

const TransactionCard: React.FC<{
  transactionId: string;
  className?: string;
}> = ({ transactionId, className }) => {
  const transaction = useAppSelector(selectTrasaction(transactionId));

  if (!transaction) {
    return null;
  }

  const {
    amount,
    status,
    metadata: {
      milestone: { name, id: milestoneId },
      payment,
      payout,
    },
    type,
  } = transaction;
  const canRequest = status === PayoutStatus.AVAILABLE;
  const deposited = status === PayoutStatus.DEPOSITED;
  const inTrasit = status === PayoutStatus.IN_TRANSIT;
  const releaseDate = formatDate(
    lodashGet(payment, "release_date") ||
      lodashGet(payment, "release_Date") ||
      ""
  );
  const estDate = formatDate(payout.est_date);
  const arrivalDate = payout.arrival_date
    ? formatDate(payout.arrival_date)
    : payout.arrival_date;
  const recieptUrl = payment.reciept_url;

  if (type === TranscationType.Spending) {
    return (
      <StyledCard className={className}>
        <StyledSpendingTopSection>
          <StyledIconContainer>
            <Icon isSrcRelative src="cash-flow/spendings.svg" />
          </StyledIconContainer>

          <div>
            <StyledTitle>{name}</StyledTitle>
            <StyledSecondaryText className="mt-1">
              Dec 6,2024
            </StyledSecondaryText>
          </div>
        </StyledSpendingTopSection>

        <StyledBottomSection className="mt-1">
          {recieptUrl && (
            <StyledButton
              openInNewTab
              variant="ghost"
              colorVariant="gray"
              className="mt-1"
              link={recieptUrl}
            >
              <Icon isSrcRelative src="reciept.svg" customSize="1.05rem" />
              View Reciept
              <Icon
                isSrcRelative
                src="chevron_right.svg"
                customSize="0.585rem"
              />
            </StyledButton>
          )}

          <StyledSpendingValue>-${amount / 100}</StyledSpendingValue>
        </StyledBottomSection>
      </StyledCard>
    );
  }

  return (
    <StyledCard className={className}>
      <StyledTopSection>
        <div>
          <StyledTitle>{name}</StyledTitle>
          {releaseDate && (
            <StyledSecondaryText className="mt-1">
              {releaseDate}
            </StyledSecondaryText>
          )}
        </div>

        <StyledValue>${amount / 100}</StyledValue>
      </StyledTopSection>

      <StyledBottomSection className="mt-4">
        <TransactionStatus payoutStatus={status} />
        {deposited && !!arrivalDate ? (
          <div className="text-center">
            <StyledSecondaryText>Deposit date</StyledSecondaryText>
            <StyledPrimaryText className="mt-1">
              {arrivalDate}
            </StyledPrimaryText>
          </div>
        ) : inTrasit && !!estDate ? (
          <div className="text-center">
            <StyledSecondaryText>Est. deposit date</StyledSecondaryText>
            <StyledPrimaryText className="mt-1">{estDate}</StyledPrimaryText>
          </div>
        ) : (
          canRequest && (
            <RequestPayoutButton milestoneId={milestoneId} className="button" />
          )
        )}
      </StyledBottomSection>

      {status === PayoutStatus.REJECTED && (
        <StyledInfo className="mt-3">
          <Icon isSrcRelative src="info_3.svg" size="sm" />
          We've noticed suspicious activity in regards to this transaction and
          after careful review we are rejecting this payout and the amount will
          be refunded to original payment method in 7-14 days.
        </StyledInfo>
      )}

      {status === PayoutStatus.IN_REVIEW && (
        <StyledInfo className="mt-3">
          <Icon isSrcRelative src="info_3.svg" size="sm" />
          The payment is being reviewed. We will update you as soon as the
          status changes.
        </StyledInfo>
      )}
    </StyledCard>
  );
};

export default TransactionCard;
